import React from 'react'
import logo from '../../Images/homeimg2/logo.svg'
import { Link } from 'react-router-dom'
import '../../App.css'
import '../../Component/HomePage/Home.css'

function FAQ() {
  return (
   
        <div className='bg-light text-dark' style={{ fontFamily: "'Lato', sans-serif !important" }}>
            <nav className="navbar navbar-default fixed-top bg-light shadow-sm">
                <div className="container">
                    <div className="navbar-header">
                        <Link className="navbar-brand m-1" to="/">
                            <img alt="Brand" src={logo} style={{ height: "20px" }} />
                        </Link>
                    </div>
                </div>
            </nav>


            <section class="section padding-top-bottom-95px" style={{paddingTop:"100px"}}>
        <div class="container dynPP">

            <h1>Frequently Asked Questions (FAQ's)</h1>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>What is the SurveyCliq.com?&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px", textAlign:"justify"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>SurveyCliq.com is an online community of members who take surveys in exchange for rewards such&nbsp; as vouchers and cash payments. Membership is entirely free &amp; so are the gift cards. Join in today by&nbsp; visiting </span></span></span><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#0000ff"}}><u>https://surveycliq.com/account</u></span></span></span><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#0000ff"}}>&nbsp;</span></span></span></p>

<p><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>Am I able to participate in your surveys?&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"1px", textAlign:"justify"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>You must be 13 years of age or older to participate in any survey. Oftentimes (but not always), surveys&nbsp; are location-dependent. Additionally, your previous survey participation plays a significant role in&nbsp; determining survey availability</span></span></span><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>.&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>What is the procedure for taking part in surveys</strong></span></span></span><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>?&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px", textAlign:"justify"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>Following the completion of your free account registration, you will receive an email to confirm your&nbsp; registration. You will be required to fill out some basic demographic information about yourself. After&nbsp; that, you will be eligible to participate in surveys. It is possible that you will be notified of new surveys by&nbsp; email, but the most efficient way to view and participate in surveys is to log into your account, either&nbsp; through the SurveyCliq.com website or our mobile app, and search for active surveys.&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>What are profile surveys, and why should I fill them accurately?&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"1px", textAlign:"justify"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>SurveyCliq.com uses profile surveys to collect information about its members in order to send them&nbsp; relevant surveys. Members earn points for completing their profiles, but they also increase their chances&nbsp; of being selected for surveys. Every few months, you should update your profile and check to see if any&nbsp; new profile questions have been added.&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>Where can I find surveys that have been published?&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"100px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>While surveys are published in the main dashboard area, they are also notified via email. </span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"100px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>Why am I not receiving surveys?&nbsp;</strong></span></span></span></p>

<p style={{marginRight:"1px", textAlign:"justify"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>There could be a number of reasons why you are not receiving surveys. If you do not reside in the&nbsp; United States, the inventory available to you is unfortunately limited. The majority of our surveys are&nbsp; conducted among residents of the United States. We'd like to add here that misrepresenting your&nbsp; residency is grounds for account termination, as we do have means of verifying your location. We do&nbsp; have surveys available to individuals outside the United States and are constantly working to expand our&nbsp; offerings. This is why it is critical to keep your profile information current. Additional reasons include&nbsp; being flagged for speeding, straight lining, or failing multiple Red Herring test questions. If you recently&nbsp; noticed a decrease in mission inventory, please contact our support team so they can look into your&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>How do I get paid?&nbsp;&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>Once you reach the minimum approved point balance of 1000, you can elect to be paid in one of several&nbsp; ways. You can request payment via PayPal and, once your address has been verified, you will receive the&nbsp;</span></span></span><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>funds within 10 business days. Alternatively, you can select one of the sveral electronic gift cards we&nbsp; offer. Make your selection prior to completing your redemption, and you will receive an electronic gift&nbsp; card within 7-10 business days.&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>W</span></span></span><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>hat is the minimum point requirement for redemption?&nbsp;</strong></span></span></span></p>

<p style={{marginRight:"1px", textAlign:"justify"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>The minimum available point balance is 1000 points, which is equivalent to $10.00 in monetary value.&nbsp; Points can only be redeemed in 1000-point increments (1000, 2000, etc.). Anything above the&nbsp; redemption point would be retained in your Point Bank and applied to your next redemption. Please&nbsp; note that when you request that your redemption be issued via PayPal, a 2% fee is added by PayPal.&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>How can I check my reward points and balance?&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>Within the SurveyCliq.com dashboard, you can view all of your points and redemption details by&nbsp; selecting the Redemption list option.&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>Do reward points expire?&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px", textAlign:"justify"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>While reward points do not technically expire, they may affect your ability to redeem them. Existing&nbsp; accounts may be terminated for violations of our terms of service, fraud, inappropriate behavior, or if&nbsp; you have not logged into and meaningfully interacted with the site for a period of more than 12 months.&nbsp; If your account is cancelled or deactivated, you will no longer be able to redeem your points regardless&nbsp; of the balance remaining.&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>How much money am I able to earn through SurveyCliq</strong><strong>.com?&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px", textAlign:"justify"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>Your earning potential is contingent upon a variety of factors, including your frequency of sign-in, your&nbsp; demographic information in comparison to available survey inventory, and final review by our survey&nbsp; requesters&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>What is a point's monetary value?&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>One point is worth $0.01, and 100 points are worth $1.00. 1000 points (minimum redemption) would be&nbsp; worth $10.00&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>What does "Over Quota" mean?&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px", textAlign:"justify"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>Often our survey requesters require data from specific demographics and, in some cases, in specific&nbsp; proportions. If they know they have met their quota based on demographic information gathered during&nbsp; the survey, they have met their quota. As a result, if you fall into that demographic, you will not be&nbsp; asked to complete the survey .Because predicting what they're looking for is rarely successful, we&nbsp; encourage you to always respond honestly.&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>What does "disqualified" mean?&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>Often our survey requesters require data from specific demographics and, in some cases, in specific&nbsp; proportions. For instance, they may wish to elicit responses from men and women between the ages of&nbsp;</span></span></span><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>18 and 54 who reside in California. If you are over 60 and live in Wyoming, you do not meet any of the&nbsp; required demographic criteria. As a result, you would be disqualified from the survey. Because&nbsp; predicting what they're looking for is rarely successful, we encourage you to always respond honestly</span></span></span><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>.&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>How to deactivate my account?&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px", textAlign:"justify"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>If you wish to cancel your membership, you can do so by sending us an email requesting to unsubscribe&nbsp; you from SurveyCliq.com. Alternatively, to deactivate your account, log in and navigate to the top&nbsp; right-hand corner of your screen to your name login. Unsubscribe using the unsubscribe option listed&nbsp; there. If you cancel your membership and unsubscribe from your account, your SurveyCliq.com&nbsp; points will expire immediately and you will no longer be able to redeem them.&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>When can my account be suspended?&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"-1px", marginRight:"1px", textAlign:"justify"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>There are numerous reasons why a user's account may be suspended, none of which are desirable. If&nbsp; you have not been able to access surveys for an extended period of time, your account may have been&nbsp; suspended. Kindly contact info@SurveyCliq.com for additional information, and include the email&nbsp; address you use to log into SurveyCliq.com in your message. If a solution exists, they can handle it&nbsp; for you.&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>What can I do if I've been waiting a long time for pending points?&nbsp;</strong></span></span></span></p>

<p style={{marginRight:"1px", textAlign:"justify"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>All survey responses are verified with the survey sponsor, which is why your reward points may not be&nbsp; immediately available in your account. This is a separate process from SurveyCliq.com and may take&nbsp; up to 4-6 weeks to complete the review process and post the rewards to your account. If you have&nbsp; waited longer than six weeks for your pending points to be verified, please contact SurveyCliq.com&nbsp; and include the email address you use to log into SurveyCliq.com in your request.&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>What are you referring to when you say "Pending and Approved Points"?&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px", textAlign:"justify"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>All survey responses are verified with the survey creator, which is why reward points are not always&nbsp; immediately available. While the verification process is ongoing, points are considered pending. Once&nbsp; the sponsoring client has successfully verified the survey, the points are automatically converted to&nbsp; approve status.&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>I requested that my redemption be sent to PayPal. Why did you take more points than you needed for&nbsp; redemption</strong></span></span></span><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>?&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px", textAlign:"justify"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>Effective November 2019, redemptions will reflect PayPal's 2% fee for PayPal transfers. Your redemption&nbsp; amount is net of this fee. This 2% fee applies only to PayPal payments; it does not apply to gift card&nbsp; payments.&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>What if the survey links are broken or do not work?&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px", textAlign:"center"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>Our technical team works diligently to ensure that each survey link works perfectly every time, but there&nbsp; are a variety of reasons why a survey may not open. To begin, force-refresh your browser and retry the&nbsp;survey link. If you are experiencing difficulties with multiple survey links, please contact&nbsp; info@SurveyCliq.com.&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>Why do I occasionally receive the message "Internal Server Error" when I attempt to launch a survey</strong></span></span></span><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>?&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px", textAlign:"justify"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>While our technical team works diligently to ensure that every survey link works perfectly every time,&nbsp; there are a variety of reasons why a survey may display this message. However, in the majority of cases,&nbsp; this survey has been deactivated, either because it has reached the maximum number of required&nbsp; responses or has been paused. If you are experiencing difficulties with more than one survey link, please&nbsp; contact info@SurveyCliq.com&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>I attempted to complete a survey and it simply froze in the middle. Do you give me credit for my&nbsp; squandered time?&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px", textAlign:"justify"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>If you are forced to close your browser window during a survey for any reason, you will not be credited&nbsp; for taking it and will receive no reward. If this happens with more than one survey, please&nbsp; contact info@SurveyCliq.com&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>I spent an inordinate amount of time completing a survey, but was terminated before it was&nbsp; completed. Was there an omission? Will I be fully reimbursed for my time</strong></span></span></span><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>?&nbsp;</span></span></span></p>

<p style={{marginRight:"1px", textAlign:"justify"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>The survey sponsor may end the session for a variety of reasons, including (but not limited to) a "red&nbsp; herring" question designed to elicit attention, technical issues missed during our tech team's thorough&nbsp; checks, and late quality checks by the survey sponsor.&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>What browser should I use when taking surveys?&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>Because our website is compatible with all browsers, you may continue to use the one you prefer. We&nbsp; do recommend periodically clearing your cookies to ensure a better experience</span></span></span><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>.&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><strong>How do I contact the helpdesk with a question or for assistance?&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px", textAlign:"justify"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>For those who are already members and can access their accounts, it is recommended that you login&nbsp; and submit your question through the helpdesk section by raising a ticket. Alternatively you can&nbsp; contact info@SurveyCliq.com for any further assistance.&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}><span style={{backgroundColor:"#ffffff"}}><strong>Dos &amp; Don'ts of </strong></span><strong>SurveyCliq</strong><span style={{backgroundColor:"#ffffff"}}><strong>.com</strong></span><strong>&nbsp;</strong></span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>Always provide truthful responses.&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>Before responding, carefully read all questions.&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>Notify helpdesk if you believe a third-party is contacting you without your consent to be contacted&nbsp; about survey opportunities or to join their panel.&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>Avoid rushing through surveys (this may account into disqualifications)</span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>Never share survey details with friends or on public forums, including survey contents, survey titles,&nbsp; survey IDs, completion time, and screenshots.&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>Do not submit photos, ideas, and videos that are inappropriate or offensive.&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>Avoid Bullying and/or offensive, profane, or hateful behavior toward other SurveyCliq.com members&nbsp; and/or SurveyCliq.com support teams.&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>Do not comment spamming on the Rewards Store&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>Refrain from completing surveys or offers containing false or erroneous information&nbsp;</span></span></span></p>

<p style={{marginLeft:"1px", marginRight:"1px"}}><span style={{fontSize:"11.039999961853027pt"}}><span style={{fontFamily:"Calibri,sans-serif"}}><span style={{color:"#000000"}}>Do not manipulate any cookie or browser setting with the intent of defrauding or circumventing&nbsp; SurveyCliq.com or any of its advertisers or sponsors' controls.</span></span></span></p>

<h5>&nbsp;</h5>

        </div>
    </section>


            <div className="container-fluid sectionWrap footerWrap" style={{ backgroundColor: "white" }}>
                <div className="row">
                    <div className="container">
                        <div className="row1">
                            <img src={logo} alt="Logo" className="logoMain" style={{ height: '20px' }} />
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-3 col-12">
                                <div className="footerlinks term">
                                    <h6 className="footerHeading"> Quick Links </h6>
                                    <p> <Link to="/Legal/TermsConditions"> Terms and Conditions </Link> </p>
                                    <p> <Link to="/Legal/PrivacyPolicy"> Privacy Policy </Link> </p>
                                    <p> <Link to="/faqs"> FAQ's </Link> </p>
                                </div>
                            </div>
                            <div className="col-md-3 col-12">
                                <div className="footerlinks">
                                    <h6 className="footerHeading"> Support </h6>
                                    <p> <Link to="mailto:support@surveycliq.com"> support@surveycliq.com</Link> </p>
                                    <p> <Link to="/account/unsubscribe"> Unsubscribe </Link> </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="socialWrap">
                                    <h6 className="footerHeading"> Find us On </h6>
                                    <Link to="https://www.facebook.com/teamarcs/" target="_blank"><i className="fa-brands fa-facebook-f" style={{ color: "#1877f2", margin: "15px" }}></i></Link>
                                    <Link to="https://www.instagram.com/teamarcstechnologies/" target="_blank"> <i className="fa-brands fa-instagram" style={{ color: '#e1306c', margin: "15px" }}></i></Link>
                                    <Link to="https://twitter.com/teamarcs" target="_blank"> <i className="fa-brands fa-twitter" style={{ color: '#1da1f2', margin: "15px" }}></i> </Link>
                                    <Link to="https://www.linkedin.com/company/teamarcs/" target="_blank"> <i className="fa-brands fa-linkedin" style={{ color: "#0077b5", margin: "15px" }}></i> </Link>
                                    <Link to="https://www.youtube.com/channel/UCcXm0wqOZfidiHDizIA4ZPA" target="_blank"> <i className="fa-brands fa-youtube" style={{ color: "#ff0000", margin: "15px" }}></i> </Link>
                                </div>
                            </div>
                            <div className="col-md-12 col-12 text-center mt-3">
                                <p className='text-black d-block'> Copyright © 2024 Survey Cliq </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    
  )
}

export default FAQ