import React from 'react'
import surveyImg from '../../../Images/Group283-min.png'


function SurveyList() {

 
  const cardStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '210px',
    padding: '5px 5px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    marginTop: '10px',
};

const textStyle = {
    flexGrow: 1,
    // textAlign: 'center',
    margin: '0px 3px',
    fontSize:"11px",
};

const imgStyle = {
    width: '40px',
    height: '40px',
    // borderRadius: '50%',
};

const buttonStyle = {
    padding: '3px 8px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#3c3c3c',
    fontSize:"13px",
    // color: '#fff',
    cursor: 'pointer',
};

const profilingSurvey = {
  width:"237px",
  margin:"-6px",
  height:"250px"
}



  return (
    <div className='container-fluid '>

      {/* Recents Survey */}
      <p>Recents</p>
      {/* <div className='Listouter mt-2 d-flex position-relative' style={Listouter}>
            <img src={surveyImg} alt=''/>
            <div className='mx-1 bg-danger' style={{height:"40px", position:"relative"}}>
              <p>Survey Test 1</p>
              <span style={span}>87% Completed</span>
            </div>
        </div> */}
      <div style={cardStyle}>
            <img
                src={surveyImg}
                alt="Placeholder"
                style={imgStyle}
            />
            <div style={textStyle}>
                <p style={{}}>Survey Ending Soon<br/><span style={{fontSize:"9px"}}> Survey Code: SQ45678 </span></p>
                {/* <span style={{fontSize:"9px"}}>87% Completed</span> */}
            </div>
            {/* <button style={buttonStyle}>Start Now</button> */}
        </div>
        <div style={cardStyle}>
            <img
                src={surveyImg}
                alt="Placeholder"
                style={imgStyle}
            />
            <div style={textStyle}>
                <p style={{}}>Survey Ending Soon<br/><span style={{fontSize:"9px"}}> Survey Code: SQ45678 </span></p>
                {/* <span style={{fontSize:"9px"}}>87% Completed</span> */}
            </div>
            {/* <button style={buttonStyle}>Start Now</button> */}
        </div>


      <hr className=''/>


      <p className='mb-3'>Profiling Survey</p>
      {/* <div className='container-fluid feedScroll' style={profilingSurvey}> */}
      <div className='container-fluid feedScroll' style={profilingSurvey}>

      <div style={cardStyle}>
            <img
                src={surveyImg}
                alt="Placeholder"
                style={imgStyle}
            />
            <div style={textStyle}>
                <p style={{}}>Survey Test 1 <br/><span style={{fontSize:"9px"}}> 87% Completed </span></p>
                {/* <span style={{fontSize:"9px"}}>87% Completed</span> */}
            </div>
            <button style={buttonStyle}>Start Now</button>
        </div>
        <div style={cardStyle}>
            <img
                src={surveyImg}
                alt="Placeholder"
                style={imgStyle}
            />
            <div style={textStyle}>
                <p style={{}}>Survey Test 1 <br/><span style={{fontSize:"9px"}}> 87% Completed </span></p>
                {/* <span style={{fontSize:"9px"}}>87% Completed</span> */}
            </div>
            <button style={buttonStyle}>Start Now</button>
        </div>

        <div style={cardStyle}>
            <img
                src={surveyImg}
                alt="Placeholder"
                style={imgStyle}
            />
            <div style={textStyle}>
                <p style={{}}>Survey Test 1 <br/><span style={{fontSize:"9px"}}> 87% Completed </span></p>
                {/* <span style={{fontSize:"9px"}}>87% Completed</span> */}
            </div>
            <button style={buttonStyle}>Start Now</button>
        </div>

        <div style={cardStyle}>
            <img
                src={surveyImg}
                alt="Placeholder"
                style={imgStyle}
            />
            <div style={textStyle}>
                <p style={{}}>Survey Test 1 <br/><span style={{fontSize:"9px"}}> 87% Completed </span></p>
                {/* <span style={{fontSize:"9px"}}>87% Completed</span> */}
            </div>
            <button style={buttonStyle}>Start Now</button>
        </div>
        
      </div>
      


    </div>
  )
}

export default SurveyList