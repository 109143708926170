import { React, useState } from 'react';
import './Login.css';
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import logowhite from '../../Images/logowhite.svg';
import loginimg from '../../Images/loginImg.png';
import Loader from '../../Component/Loader/Loader';

function Login({ onLogin }) {
  localStorage.removeItem('userDetails');
  const [errors, setErrors] = useState({});
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const SignIn = process.env.REACT_APP_AUTH_URL;

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const navigate = useNavigate();
  
  const [user, setUser] = useState({
    username: "",
    password: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };

  const login = () => {
    const errors = {};

    if (!user.username) {
      errors.username = "Username is required";
    }

    if (!user.password) {
      errors.password = "Password is required";
    }

    setErrors(errors);

    if (user.username !== '' && user.password !== '') {
      setIsLoading(true); // Set loading to true before API call
      axios.post(`${SignIn}/api/auth/user/signin`, user)
      .then(res => {
        const userDetails = res.data;
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
        var details = localStorage.getItem('userDetails');
        var user = JSON.parse(details);
        var role = user.role;
        
        if(role === "2"){
          navigate(`/User/Dashboard`); 
          onLogin(userDetails); // Notify App component about the login
        }
        if (res.data.message === 'password') {
          setErrors({ password: 'Please enter the correct password'});
        } else if (res.data.message === 'email') {
          setErrors({ username: 'Please sign in with registered email' });
        }
        
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after API call
      });
    }
  };

  return (
    <div className="container-fluid signinBGs">
      {isLoading && <Loader />} {/* Render Loader when loading */}
      <div className="row">
        <div className="container">
          <section className="row page-section bgvdolgn formBG">
            <div className="col-lg-6 col-sm-12">
              <div className="row rightWraps">
                <div className="col-sm-12 panel-wrapper formSignBgs frmlgn">
                  <div className="frm-fill formSignBox">
                    <div className="display-flex-column align-content-center justify-content-center full-height flex-center login-container">
                      <div className="main-panel__content main-panel__content--login">
                        <Link className="navbar-brand mobileLogo" to="/">
                          <img src={logowhite} alt="logo" className="logo" height="70"/>
                        </Link>

                        <form onSubmit={handleSubmit}>
                          <h1 className="main-panel__title">Sign In</h1>
                          <hr className="frmhr"/>
                          <div className="pf-form">
                            <div className="pf-form__message pf-form__message--error pf-form__message--padding"></div>
                            <div className="pf-form__textfield">
                              <label>Email</label>
                              <i> <img src="~/image/email.png" alt="icon"/>  </i>
                              <input type="text" value={user.username} onChange={handleChange} name="username" placeholder="Email" className="pf-form__textfield__input" />
                              <div className='pf-form__message pf-form__message--error text-left '>
                                {errors.username && <p className='text-danger' style={{fontSize:"12px"}}>{errors.username}</p>}
                              </div>
                            </div>
                            <div className="pf-form__textfield">
                              <label> Password </label>
                              <i> <img src="~/image/password.png" alt="icon"/>  </i>
                              <input type={isPasswordVisible ? "text" : "password"} value={user.password} onChange={handleChange} name="password" className="pf-form__textfield__input pf-clearer password pwd" placeholder="Password" />
                              <span type="button"  title={isPasswordVisible ? "Hide" : "Show"} onClick={togglePasswordVisibility} className={`toggle-password fa-icon ${isPasswordVisible ? "fa fa-eye-slash" : "fa fa-eye"}`} ></span>
                              <div className='pf-form__message pf-form__message--error text-left'>
                                {errors.password && <p className='text-danger' style={{fontSize:"12px"}}>{errors.password}</p>}
                              </div>
                              <div className="text-right mt-4">
                                <Link to='/Account/ForgotPassword' id="Frogot_link" className="d-flex justify-content-end frgt" style={{marginTop:"10px", marginBottom:"10px"}}>ForgotPassword</Link>
                              </div>
                            </div>
                            <div className="pf-form__textfield mb-1qq">
                              <input type='submit' value="Submit" className="pf-form__textfield-button pf-form__textfield-button--submit lgnbtn" style={{width:"100%"}} />
                            </div>
                          </div>
                        </form>
                        <div className="pf-copyright">Don't have an account? <Link className="text-large font-weight-bold1 uk-visible@l signupbtn" to='/account'>Sign Up Now</Link> </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-sm-12">
              <div className="row">
                <div className="ctrRight leftsideImg">
                  <Link className="navbar-brand mb-4" to="/">
                    <img src={logowhite} alt="logo" className="logo" height="70"/>
                  </Link>
                  <img src={loginimg} alt="girl" className="phone-img img-fluid" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Login;
