
import { React, useState } from 'react';
import '../Login/Login.css';
import axios from "axios";
import { useNavigate, Link, useParams } from "react-router-dom";
import logowhite from '../../Images/logowhite.svg';
import loginimg from '../../Images/forgotpw.png';
import Loader from '../../Component/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ResetPassword() {
  localStorage.removeItem('userDetails');
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  // const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  // const [mixMatched, setMixMatched] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const SignIn = process.env.REACT_APP_AUTH_URL;
  const {id, token} = useParams();

  // const navigate = useNavigate();
  
  const [user, setUser] = useState({
    password: "",
    confirmPassword: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // setMixMatched('')
    ForgotPass();
  };

  const ForgotPass = () => {
    const errors = {};

    if (!user.password) {
      errors.password = "Password is required";
    }
    if (!user.confirmPassword) {
      errors.confirmPassword = "Confirm Password is required";
    }
    if(user.password!== user.confirmPassword){
      errors.mixMatched="New Password and Confirm Password does not matched"
    }
    setErrors(errors);
    // console.log(user);
    if (user.password !== '' || user.confirmPassword!== '' || user.password !== user.confirmPassword ) {
      setIsLoading(true); // Set loading to true before API call
      axios.post(`${SignIn}/api/user/resetPassword/${id}/${token}`, user)
      .then(res => {
        // console.log(res.data.message);
        // toast.success("Password update successfully");
        navigate('/login')
      })
      .catch(err => {
        console.log(err);
        toast.success("Password does not update");
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after API call
      });
    }
  };


    return (
        <div className="container-fluid signinBGs">
        {isLoading && <Loader />} {/* Render Loader when loading */}
        <ToastContainer/>
        <div className="row">
          <div className="container">
            <section className="row page-section bgvdolgn formBG">
              <div className="col-lg-6 col-sm-12">
                <div className="row rightWraps">
                  <div className="col-sm-12 panel-wrapper formSignBgs frmlgn">
                    <div className="frm-fill formSignBox">
                      <div className="display-flex-column align-content-center justify-content-center full-height flex-center login-container">
                        <div className="main-panel__content main-panel__content--login">
                          <Link className="navbar-brand mobileLogo" to="/">
                            <img src={logowhite} alt="logo" className="logo" height="70"/>
                          </Link>
  
                          <form onSubmit={handleSubmit} className='mb-0'>
                            <h1 className="main-panel__title">Reset Password</h1>
                            {/* <hr className="frmhr"/> */}
                            {errors.mixMatched && <p className='text-danger' style={{fontSize:"12px"}}>{errors.mixMatched}</p>}
                            <div className="pf-form">
                              <div className="pf-form__message pf-form__message--error pf-form__message--padding"></div>
                              <div className="pf-form__textfield">
                                <label></label>
                                <i> <img src="~/image/email.png" alt="icon"/>  </i>
                                <input type="password" value={user.password} onChange={handleChange} name="password" placeholder="Enter your new Password" className="pf-form__textfield__input" />
                                <div className='pf-form__message pf-form__message--error text-left '>
                                  {errors.password && <p className='text-danger' style={{fontSize:"12px"}}>{errors.password}</p>}
                                </div>
                              </div>
                              <div className="pf-form__textfield">
                                {/* <label>Email</label> */}
                                <i> <img src="~/image/email.png" alt="icon"/>  </i>
                                <input type="password" value={user.confirmPassword} onChange={handleChange} name="confirmPassword" placeholder="Confirm Password" className="pf-form__textfield__input" />
                                <div className='pf-form__message pf-form__message--error text-left '>
                                  {errors.confirmPassword && <p className='text-danger' style={{fontSize:"12px"}}>{errors.confirmPassword}</p>}
                                </div>
                              </div>
                              
                              <div className="pf-form__textfield mt-3">
                                <input type='submit' value="Submit" className="pf-form__textfield-button pf-form__textfield-button--submit lgnbtn" style={{width:"100%"}} />
                              </div>
                            </div>
                          </form>
                          <div className="pf-copyright"> <Link className="text-large font-weight-bold1 uk-visible@l signupbtn" to='/account'>Sign Up Now</Link> </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div className="col-lg-6 col-sm-12">
                <div className="row">
                  <div className="ctrRight leftsideImg">
                    <Link className="navbar-brand mb-4" to="/">
                      <img src={logowhite} alt="logo" className="logo" height="70"/>
                    </Link>
                    <img src={loginimg} alt="girl" className="phone-img img-fluid" />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    )
}

export default ResetPassword