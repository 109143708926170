import {React, useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import './Home.css'
import logo from '../../Images/homeimg2/logo.svg'
import logo_white from '../../Images/homeimg2/logo_white.svg'
import dashboardImg from '../../Images/homeimg2/dashboardImage.png'
import earth from '../../Images/homeimg2/earth.png'
import rectangle from '../../Images/homeimg2/rectangle.png'
import motivatesusimg from '../../Images/homeimg2/motivatesusimg.png'
import featureBoy from '../../Images/homeimg2/featureboy.png'
import feature from '../../Images/homeimg2/features.png'
import favicon from '../../Images/homeimg2/favicon.png'

function Home() {
    localStorage.removeItem('userDetails')
    const headerRef = useRef(null);
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
        if (headerRef.current) {
            setIsSticky(window.pageYOffset > headerRef.current.offsetTop);
            }
        };

    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
        };
    }, []);



  return (
    <div className='width100'>


<div className="container-fluid topBannerBox">
        <div className="row">
            {/* <!-- Navbar --> */}
            <div  id="myHeader" ref={headerRef}  className={`mainHeader ${isSticky ? 'stickyHead' : ''}`}>
                <div className="container">
                    <div className="row">
                        <nav className="navbar navbar-expand-lg navBG">
                            <div className="container-fluid">
                                <Link className="navbar-brand" to="/">
                                    <img src={logo} alt="logo" className="logo_main lgS"/>
                                    <img src={logo_white} alt="logo" className="logo_main smS"/>
                                </Link>
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                                        aria-label="Toggle navigation" style={{backgroundColor:"transparent"}}>
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav ms-auto">
                                        <li className="nav-item">
                                            <Link className="nav-link " aria-current="page" to="#">Home</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="#">Aboutus</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="#">What We Do</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="#">Contact Us</Link>
                                        </li>
                                        <li className="nav-item userLogin">
                                            <Link className="nav-link" to="/login"><i className="fa-solid fa-user"></i>Log In</Link>
                                        </li>
                                        <li className="nav-item loginTxt">
                                            <Link className="nav-link" to="/account">Get Started</Link>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-10 offset-md-1 col-12 bannerTextWrap sectionWrap">
                        <h1 className="headings"> Start Quick Surveys & Earn Rewards </h1>
                        <div className="col-md-12 col-12">
                            <p className="paraText">
                                Waiting in a queue or in a subway? Share your opinion, complete the survey and earn
                                rewards and easy cash.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-10 offset-md-1 dashImg">
                        <img src={dashboardImg} alt="dashboardImage" className="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    {/* <!-- Explore Surveycliq --> */}
    <div className="exploreWrap">
        <div className="container">
            <div className="explorIMG">
                <img src={earth} alt="earth" className="img-fluid earthimg"/>
                <img src={rectangle} alt="rectangle" className="img-fluid rectimg"/>
            </div>
            <div className="row explrBG">
                <div className="col-md-10 offset-md-1 col-12">
                    <h2 className="headings">Explore Surveycliq</h2>
                    <p className="paraText">
                        Think of the opportunity of earning cash and getting rewarded for sharing your opinion.
                        Survey Cliq is a platform where you can earn cash and rewards simply by sharing your feedback.
                    </p>
                    <p className="paraText">
                        Whether you own a smartphone or computer, you can easily fill out surveys and can earn
                        rewards. After your registration, we send invitations to you by email with a link to participate in
                        surveys that can be filled out easily. With Survey Cliq, you will get prior notification about how long
                        the survey will take so that you can complete the survey whenever suits you best.
                    </p>
                    <p className="paraText">
                        At Survey Cliq, your privacy is safe at all times. The information you provide us is
                        only used to select you as a survey respondent. We will reward you for participating in the survey that
                        can be redeemed effortlessly.
                    </p>
                </div>
            </div>

        </div>
    </div>

    {/* <!-- Product Highlights --> */}
    <div className="productWrap sectionWrap relative">
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-12">
                    <h2 className="headings">Product Highlights</h2>
                    <div className="row">
                        <div className="col-md-4 productBoxMain">
                            <div className="productBox">
                                <div className="productBoxA">
                                    <i className="fas fa-sign-in-alt"></i>
                                    <p className="paraText">Affiliate & Social <span className="displayBlock">Traffic</span></p>
                                </div>
                                <div className="productBoxB">
                                    <i className="fas fa-sign-in-alt"></i>
                                    <p className="paraText"> Register for Free </p>
                                    <p className="paraTextLow"> Join us as an advisor from your mobile, laptop, or tablet. Register and connect with the highest paid surveys in the world, 100% free! </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 productBoxMain">
                            <div className="productBox">
                                <div className="productBoxA">
                                    <i className="fa-solid fa-envelope"></i>
                                    <p className="paraText">Email & Messaging <span className="displayBlock">Broadcasting</span></p>
                                </div>
                                <div className="productBoxB">
                                    <i className="fas fa-clipboard-list"></i>
                                    <p className="paraText"> Participate in the Surveys </p>
                                    <p className="paraTextLow"> Participate and share your ideas by answering surveys and joining interviews. Answer anywhere, anytime. Do it in an easy fashion! </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 productBoxMain">
                            <div className="productBox">
                                <div className="productBoxA">
                                    <i className="fas fa-mobile-alt"></i>
                                    <p className="paraText">Available in Mobile <span className="displayBlock">Applications</span></p>
                                </div>
                                <div className="productBoxB">
                                    <i className="fas fa-gift"></i>
                                    <p className="paraText"> Complete the Survey </p>
                                    <p className="paraTextLow"> After you’ve earned your loyalty points, redeem them in e-vouchers, gift cards, or cash easily! </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    {/* <!-- What motivates us to do this? --> */}
    <div className=" motivateWrap sectionWrap">
        <div className="container">
            <div className="row align-items-md-end">
                <div className="col-md-6">
                    <div className="headBox">
                        <h3 className="headings">What <span className="colorRDtxt displayBlock">Motivates us</span> <span className="displayBlock">to do this?</span></h3>
                    </div>
                    <div className="paraHead">
                        <p className="paraText mt-3">
                            Every brand is curious to know what people think about their products. Share your
                            valuable opinion on your favorite brands, products, and services. Your opinion can make a huge difference
                            in improvising the quality and services of the products and brands and get rewards and gifts for your
                            contribution. Redeem your cash & rewards effortlessly and help us build a reliable community by referral
                            and get incentivized.
                        </p>
                        <p className="paraText">
                            Survey Cliq is our online platform that allows you to take paid surveys, test items, and
                            rate advertisements!
                        </p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="motivationimg">
                        <img src={motivatesusimg} alt="motivatesusimg" className="img-fluid"/>
                    </div>
                </div>
            </div>
        </div>
    </div>

    {/* <!-- Our Features --> */}
    <div className="ourfeatureWrap sectionWrap">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="row ourBG align-items-md-end">
                        <div className="col-md-3 relative">
                            <div className="motivBG">
                                <img src={featureBoy} alt="featureboy" className="img-fluid"/>
                            </div>
                        </div>

                        <div className="col-md-9">
                            <div className="headBox">
                                <h3 className="headings">Our Features</h3>
                                <p className="paraText">
                                    Whether you own a smartphone or computer, you can easily fill out surveys and can earn
                                    rewards. After your registration, we send invitations to you by email with a link to participate in
                                    surveys that can be filled out easily. With Survey Cliq, you will get prior notification about how long
                                    the survey will take so that you can complete the survey whenever suits you best. At Survey Cliq, your
                                    privacy is safe at all times. The information you provide us is only used to select you as a survey
                                    respondent. We will reward you for participating in the survey that can be redeemed effortlessly.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div className="col-md-12">
            <div className="featureimg">
                <img src={feature} alt="features" className="img-fluid"/>
            </div>
        </div>
    </div>


    {/* <!-- Contactus --> */}
    <div className="contactWrap sectionWrap">
        <div className="container">
            <div className="row reBG">
                <div className="col-md-5 col-12">
                    <div className="contactHead">
                        <div className="imgHead">
                            <img src={favicon} alt="favicon" className="img-fluid favicon"/>
                        </div>
                        <h3 className="heading">We'd love to hear <span className="displayBlock">from you!</span></h3>

                        <div className="socialWrap">
                            {/* <Link to="https://www.facebook.com/teamarcs/" className="fb" target="_blank" title="facebook"><i className="fa-brands fa-facebook-f"></i></Link> */}
                            <Link to="https://www.facebook.com/teamarcs/" className="linkedIn" target="_blank" title="facebook"><i className="fa-brands fa-facebook-f"></i></Link>
                            <Link to="https://www.linkedin.com/company/teamarcs/" className="linkedIn" target="_blank" title="linkedin"><i className="fa-brands fa-linkedin-in"></i></Link>
                            <Link to="https://www.instagram.com/teamarcstechnologies/" className="instagram" target="_blank" title="instagram"><i className="fa-brands fa-instagram"></i></Link>
                            <Link to="https://twitter.com/teamarcs" className="twitter" target="_blank" title="twitter"><i className="fa-brands fa-x-twitter"></i></Link>
                            <Link to="https://www.youtube.com/channel/UCcXm0wqOZfidiHDizIA4ZPA" className="twitter" target="_blank" title="twitter"><i className="fa-brands fa-youtube"></i></Link>

                        </div>
                    </div>

                </div>

                <div className="col-md-7 col-12">
                    <div className="row">
                        <div className="contactfrm">
                            <h3 className="heading1">Contact Us</h3>
                            <form action="">
                                <div className="row">
                                    <div className="col-12 col-md-6 ">
                                        <div className="mb-3">
                                            <input type="text" className="form-control custom-input" placeholder="Name*"/>
                                        </div>

                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="mb-3">
                                            <input type="number" className="form-control custom-input" placeholder="Mobile No*"/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12">
                                        <div className="mb-3">
                                            <input type="text" className="form-control custom-input" placeholder="Email*"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="mb-3">
                                            <textarea name="message" placeholder="Message*" id="" rows="3"
                                                      className="form-control custom-textarea"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="mb-3">
                                            <button className="btn myBTN">Submit</button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>


    <div className="footerWrap sectionWrap">
        <div className="container">
            <div className="row align-items-md-center">
                <div className="col-md-6 col-12">
                    <div className="footerlinks">
                        <img src={logo_white} alt="logo" className="logo_main"/>
                        <p> <i className="fa-solid fa-phone"></i> <Link className='text-white' to="callto:9958822608">9958822608</Link> </p>
                        <p>
                            <i className="fas fa-map-marker-alt"></i>
                            C56, A/28, Ground Floor, Sector 62,<br /> Noida, Uttar Pradesh 201309, India
                        </p>
                        <p> <i className="fas fa-envelope"></i> Office: <Link className='text-white' to="mailto:info@teamarcs.com ">info@teamarcs.com </Link> </p>
                    </div>

                </div>

                <div className="col-md-3 col-12">
                    <div className="footerlinks">
                        <h6 className="footerHeading"> Quick Links </h6>
                        <p> <Link to="/Legal/TermsConditions" className='text-white'> Terms and Conditions </Link> </p>
                        <p> <Link to="/Legal/PrivacyPolicy" className='text-white'> Privacy Policy </Link> </p>
                        <p> <Link to="/faqs" className='text-white'> FAQ's </Link> </p>
                    </div>
                </div>
                <div className="col-md-3 col-12">
                    <div className="footerlinks">
                        <h6 className="footerHeading"> Support </h6>
                        <p> <Link to="mailto:support@surveycliq.com" className='text-white'>support@surveycliq.com </Link> </p>
                        <p> <Link to="#" className='text-white'> About Us </Link> </p>
                        <p> <Link to="/account/unsubscribe" className='text-white'> Unsubscribe </Link> </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr />
    <div className="container-fluid ">
        <div className="row">
            <div className="col-md-12 col-12 text-center">
                <p style={{color:"#fff"}}>
                    Copyright © 2024 SurveyCliq
                </p>

            </div>
        </div>

    </div>
   
    </div>
  ) 
}

export default Home