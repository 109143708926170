import { React, useState } from 'react';
import '../Login/Login.css';
import axios from "axios";
import { Link,useParams, useNavigate  } from "react-router-dom";
import logowhite from '../../Images/logowhite.svg';
// import loginimg from '../../Images/loginImg.png';
// import Loader from '../../Component/Loader/Loader';

function ProfilingSurvey({onLogin}) {
    localStorage.removeItem('userDetails');
    const uid = useParams();
    const navigate = useNavigate();
    localStorage.removeItem('userDetails');
    const [errors, setErrors] = useState({});
    const [, setIsLoading] = useState(false); // Add loading state
    const SignIn = process.env.REACT_APP_AUTH_URL;

    
    const [user, setUser] = useState({
      education: "",
      employmentStatus:"",
      race:"",
      industries:"",
      responsibility:"",
      relationship:"",
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setUser({
        ...user,
        [name]: value
      });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      login();
    };
  
    const login = () => {
      const errors = {};
  
      if (!user.education) {
        errors.education = "Field is required";
      }
      if (!user.employmentStatus) {
        errors.employmentStatus = "Field is required";
      }
      if (!user.race) {
        errors.race = "Field is required";
      }
      if (!user.industries) {
        errors.industries = "Field is required";
      }
      if (!user.zipCode) {
        errors.responsibility = "Field is required";
      }
      if (!user.zipCode) {
        errors.relationship = "Field is required";
      }
      setErrors(errors);
  
      if (user.education !== '' || user.employmentStatus !== '' || user.race !== '' || user.industries !== '' || user.responsibility !== '' || user.relationship!=='') {
        // setIsLoading(true); // Set loading to true before API call
        // console.log(uid ," 0 ", user);  
        axios.post(`${SignIn}/api/user/ProfilingSurvey/${uid.uid}`, user)
        .then(res => {
            // console.log(res);
            if(res.data.message==='right'){
                navigate(`/Thankyou/SurveyProfiling/${uid}`)
            }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false); // Set loading to false after API call
        });
      }
    };

  return (
    <div className="container-fluid signinBGs">
    
    <div className="row">
      <div className="container">
        <section className="row page-section bgvdolgn formBG">
          <div className="col-lg-12 col-sm-12" >
            <div className="row rightWraps">
              <div className="col-sm-12 panel-wrapper formSignBgs frmlgn">
                <div className="frm-fill formSignBox" style={{left:"50%", height:"90vh"}}>
                  <div className="display-flex-column align-content-center justify-content-center full-height flex-center login-container">
                    <div className="main-panel__content main-panel__content--login">
                      <Link className="navbar-brand" to="/">
                        <img src={logowhite} alt="logo" className="logo" height="70"/>
                      </Link>

                      <form onSubmit={handleSubmit}>
                        <h1 className="main-panel__title">Profiling Survey</h1>
                        <hr className="frmhr"/>
                        <div className="pf-form feedScroll" style={{height:"55vh"}}>
                          <div className="pf-form__message pf-form__message--error pf-form__message--padding"></div>
                          
                          <div className='row'>
                            <div className="pf-form__textfield col-lg-8 col-md-12">
                                <label>What is the highest level of education you have completed?</label>
                                <i>
                                    <img src="~/image/email.png" alt="icon" />
                                </i>
                                <select
                                    name="education"
                                    value={user.education}
                                    onChange={handleChange}
                                    className="pf-form__textfield__input"
                                >
                                    <option value="" disabled>
                                    --Select--
                                    </option>
                                    <option value="Associates">Associates degree</option>
                                    <option value="Bachelor">Bachelor's degree</option>
                                    <option value="High School">High school graduate</option>
                                    <option value="Masters">Masters or professional degree</option>
                                </select>
                                <div className='pf-form__message pf-form__message--error text-left'>
                                    {errors.education && (
                                    <p className='text-danger' style={{ fontSize: "12px" }}>
                                        {errors.education}
                                    </p>
                                    )}
                                </div>
                            </div>
                            <div className="pf-form__textfield col-lg-8 col-md-12">
                                <label>What is your current employment status?</label>
                                <i>
                                    <img src="~/image/email.png" alt="icon" />
                                </i>
                                <select
                                    name="employmentStatus"
                                    value={user.employmentStatus}
                                    onChange={handleChange}
                                    className="pf-form__textfield__input"
                                >
                                    <option value="" disabled>
                                    --Select--
                                    </option>
                                    <option value="Employed full-time">Employed full-time</option>
                                    <option value="Employed part-time">Employed part-time</option>
                                    <option value="Prefer not to answer">Prefer not to answer</option>
                                    <option value="Student">Student</option>
                                    <option value="Retired">Retired</option>
                                    <option value="Other">Other</option>
                                </select>
                                <div className='pf-form__message pf-form__message--error text-left'>
                                    {errors.employmentStatus && (
                                    <p className='text-danger' style={{ fontSize: "12px" }}>
                                        {errors.employmentStatus}
                                    </p>
                                    )}
                                </div>
                            </div>
                            <div className="pf-form__textfield col-lg-8 col-md-12">
                                <label>Which of the following best describes your race?</label>
                                <i>
                                    <img src="~/image/email.png" alt="icon" />
                                </i>
                                <select
                                    name="race"
                                    value={user.race}
                                    onChange={handleChange}
                                    className="pf-form__textfield__input"
                                >
                                    <option value="" disabled>
                                    --Select--
                                    </option>
                                    <option value="Asian - Asian Indian">Asian - Asian Indian</option>
                                    <option value="Asian - Chinese">Asian - Chinese</option>
                                    <option value="Asian - Japanese">Asian - Japanese</option>
                                    <option value="Asian - Japanese">Asian - Japanese</option>
                                    <option value="Asian - Other">Asian - Other</option>
                                    <option value="Other">Other</option>
                                </select>
                                <div className='pf-form__message pf-form__message--error text-left'>
                                    {errors.race && (
                                    <p className='text-danger' style={{ fontSize: "12px" }}>
                                        {errors.race}
                                    </p>
                                    )}
                                </div>
                            </div>
                            <div className="pf-form__textfield col-lg-8 col-md-12">
                                <label>Do you work in any of the following industries?</label>
                                <i>
                                    <img src="~/image/email.png" alt="icon" />
                                </i>
                                <select
                                    name="industries"
                                    value={user.industries}
                                    onChange={handleChange}
                                    className="pf-form__textfield__input"
                                >
                                    <option value="" disabled>
                                    --Select--
                                    </option>
                                    <option value="Accounting">Accounting</option>
                                    <option value="Advertising">Advertising</option>
                                    <option value="Architecture">Architecture</option>
                                    <option value="Architecture">Architecture</option>
                                    <option value="Banking/Financial">Banking/Financial</option>
                                    <option value="Computer Hardware">Computer Hardware</option>
                                    <option value="Construction">Construction</option>
                                    <option value="Other">Other</option>
                                </select>
                                <div className='pf-form__message pf-form__message--error text-left'>
                                    {errors.industries && (
                                    <p className='text-danger' style={{ fontSize: "12px" }}>
                                        {errors.industries}
                                    </p>
                                    )}
                                </div>
                            </div>
                           
                            <div className="pf-form__textfield col-lg-8 col-md-12">
                                <label>What is your job title, level or responsibility?</label>
                                <i>
                                    <img src="~/image/email.png" alt="icon" />
                                </i>
                                <select
                                    name="responsibility"
                                    value={user.responsibility}
                                    onChange={handleChange}
                                    className="pf-form__textfield__input"
                                >
                                    <option value="" disabled>
                                    --Select--
                                    </option>
                                    <option value="Lab Facility Manager">Lab Facility Manager</option>
                                    <option value="Laboratory Director">Laboratory Director</option>
                                    <option value="Account Manager">Account Manager</option>
                                    <option value="Management Analyst">Management Analyst</option>
                                    <option value="Allergists">Allergists</option>
                                    <option value="Network Analyst">Network Analyst</option>
                                    <option value="Brand Manager">Brand Manager</option>
                                    <option value='Other'>Other</option>
                                </select>
                                <div className='pf-form__message pf-form__message--error text-left'>
                                    {errors.responsibility && (
                                    <p className='text-danger' style={{ fontSize: "12px" }}>
                                        {errors.responsibility}
                                    </p>
                                    )}
                                </div>
                            </div>
                            <div className="pf-form__textfield col-lg-8 col-md-12">
                                <label>What is your relationship status?</label>
                                <i>
                                    <img src="~/image/email.png" alt="icon" />
                                </i>
                                <select
                                    name="relationship"
                                    value={user.relationship}
                                    onChange={handleChange}
                                    className="pf-form__textfield__input"
                                >
                                    <option value="" disabled>
                                    --Select--
                                    </option>
                                    <option value="Single">Single, never married</option>
                                    <option value="Married">Married</option>
                                    <option value="divorced">Separated, divorced or widowed</option>
                                    <option value="Other">Other</option>
                                </select>
                                <div className='pf-form__message pf-form__message--error text-left'>
                                    {errors.relationship && (
                                    <p className='text-danger' style={{ fontSize: "12px" }}>
                                        {errors.relationship}
                                    </p>
                                    )}
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className="pf-form__textfield mb-1qq d-flex justify-content-center mt-2">
                            <input type='submit' value="Submit" className="pf-form__textfield-button pf-form__textfield-button--submit lgnbtn" style={{width:"30%"}} />
                          </div>                        
                      </form>
                      {/* <div className="pf-copyright">Don't have an account? <Link className="text-large font-weight-bold1 uk-visible@l signupbtn" to='/account'>Sign Up Now</Link> </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  )
}

export default ProfilingSurvey