import React, { useState } from 'react';
import './AdminLogin.css';
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
// import Swal from 'sweetalert2';
import logo from '../../Images/homeimg2/logo.svg';
import Loader from '../Loader/Loader';
import $ from 'jquery';

function Login({ onLogin }) {
  localStorage.removeItem('userDetails')
  const [errors, setErrors] = useState({});
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  
  const [user, setUser] = useState({
    username: "",
    password: ""
  });

  const [loading, setLoading] = useState(false);

  $(".toggle-password").click(function () {
    var $pwd = $(".pwd");
    if ($pwd.attr("type") === "password") {
        $pwd.attr("type", "text");
        $('.toggle-password').removeClass("fa-eye").addClass("fa-eye-slash");
        $('.toggle-password').attr("title", "Hide");
    } else {
        $pwd.attr("type", "password");
        $('.toggle-password').attr("title", "Show");
        $('.toggle-password').removeClass("fa-eye-slash").addClass("fa-eye");
    }
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };

  const login = () => {

    const errors = {};

    if (!user.username) {
      errors.username = "Username is required";
    }

    if (!user.password) {
      errors.password = "Password is required";
    }

    setErrors(errors);

    if (user.username !== '' && user.password !== '') {
      setLoading(true)
      axios.post(`${process.env.REACT_APP_AUTH_URL}/api/auth/admin/signin`, user)
      .then(res => {
        const userDetails = res.data;
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
        var details = localStorage.getItem('userDetails');
        var user = JSON.parse(details);
        var role = user.role;

        switch (role){
          case "1": 
            setLoading(true);
            navigate(`/admin/Dashboard`); 
          break;
          case "0": 
            setLoading(true);
            navigate(`/superadmin/Dashboard`); 
          break;
          default:
            console.log(res.data.message);
            if (res.data.message === 'password') {
              // Unauthorized - invalid credentials
              setErrors({ password: 'Please enter the correct password'});
            } else if (res.data.message === 'email') {
              // Not Found - user not registered
              setErrors({ username: 'Please sign in with registered email' });
            }
          break;
        }
        onLogin(userDetails);
      })
      .catch(err => {
        // Swal.fire({
        //   title: 'Login Error',
        //   text: "user not found",
        //   icon: 'error',
        //   confirmButtonText: 'OK'
        // });
      })
      .finally(() => {
        setLoading(false);
      });
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="adminLogin">
        <main>
          <div className="container">
            <div className="">
              <div className="">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <br /><br />
                    <a ><img src={logo} alt="" className="logoN"/></a>
                  </div>
                </div>
                <div className="forms new-frms banner-form" id="formContent" style={{padding:"20px 0 30px"}}>
                  <form onSubmit={handleSubmit}>
                    <h3 className="text-center mb-3" style={{color:"white"}}>Admin Login</h3>
                    <div className="text-danger"></div>
                    <div className="row">
                      <div className="form-group col-sm-12 m-t-20">
                        <label className="control-label">UserName or Email</label>
                        <input value={user.username} onChange={handleChange} name="username" className="form-control" placeholder='UserName or Email' />
                        <div className='pf-form__message pf-form__message--error text-left mx-3'>
                          {errors.username && <p className='text-danger' style={{fontSize:"12px"}}>{errors.username}</p>}
                        </div>
                      </div>
                      <div className="form-group col-sm-12 m-t-20">
                        <label className="control-label">Password</label>
                        <input type={isPasswordVisible ? "text" : "password"} value={user.password} onChange={handleChange} name="password" className="pf-form__textfield__input pf-clearer password pwd" placeholder="Password" />
                        <span type="button" title={isPasswordVisible ? "Hide" : "Show"} onClick={togglePasswordVisibility} className={`toggle-password fa-icon ${isPasswordVisible ? "fa fa-eye-slash" : "fa fa-eye"}`} style={{color:"black",margin:"10px"}}></span>
                        <div className='pf-form__message pf-form__message--error text-left mx-3'>
                          {errors.password && <p className='text-danger' style={{fontSize:"12px"}}>{errors.password}</p>}
                        </div>
                      </div>
                      
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <button type="submit" className="w-100 mt-3 button btn-block text-center iq-mt-30"> Login </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default Login;
