// App.js
import './App.css';
import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import AdminLogin from './Component/AdminLogin/AdminLogin';
import Login from './View/Login/SignIn';
import Register from './View/Register/SignUp';
import DefaultLayout from './View/DefaultLayout';
import Home from './Component/HomePage/Home';
import Unauthorized from './View/Unauthorized';
import TermsConditions from './View/Legal/TermsConditions';
import PrivacyPolicy from './View/Legal/PrivacyPolicy';
import FAQ from './View/Legal/FAQ';
import ForgotPassword from './View/ForgotPassword/ForgotPassword';
import ResetPassword from './View/ForgotPassword/ResetPassword';
import ActivationCode from './View/SignupSurvey/ActivationCode';
import UserDetails from './View/SignupSurvey/UserDetails';
import ProfilingSurvey from './View/SignupSurvey/ProfilingSurvey';
import Thankyou from './View/SignupSurvey/Thankyou';
import { UserProvider } from './Component/UserContext';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userDetails = localStorage.getItem('userDetails');
    if (userDetails) {
      setIsLoggedIn(true);
    }
  }, [isLoggedIn]);

  const handleLogin = (user) => {
    localStorage.setItem('userDetails', JSON.stringify(user));
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('userDetails');
    setIsLoggedIn(false);
    navigate('/login'); // Redirect to login page after logout
  };

  return (
    <UserProvider>
      <Routes>
        {!isLoggedIn ? (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route path="/admin/login" element={<AdminLogin onLogin={handleLogin} />} />
            <Route path="/account" element={<Register />} />
            <Route path="/Legal/TermsConditions" element={<TermsConditions />} />
            <Route path="/Legal/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/faqs" element={<FAQ />} />
            <Route path="/Account/ForgotPassword" element={<ForgotPassword />} />
            <Route path="/reset-password/:id/:token" element={<ResetPassword />} />
            <Route path="/ActivationCode/:uid" element={<ActivationCode />} />
            <Route path="/userDetails/:uid" element={<UserDetails />} />
            <Route path="/ProfilingSurvey/:uid" element={<ProfilingSurvey />} />
            <Route path="/Thankyou/SurveyProfiling/:uid" element={<Thankyou />} />
            <Route path="*" element={<Unauthorized />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route path="/admin/login" element={<AdminLogin onLogin={handleLogin} />} />
            <Route path="/account" element={<Register />} />
            <Route path="/Legal/TermsConditions" element={<TermsConditions />} />
            <Route path="/Legal/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/faqs" element={<FAQ />} />
            <Route path="/Account/ForgotPassword" element={<ForgotPassword />} />
            <Route path="/reset-password/:id/:token" element={<ResetPassword />} />
            <Route path="/ActivationCode/:uid" element={<ActivationCode />} />
            <Route path="/userDetails/:uid" element={<UserDetails />} />
            <Route path="/ProfilingSurvey/:uid" element={<ProfilingSurvey />} />
            <Route path="/Thankyou/SurveyProfiling/:uid" element={<Thankyou />} />
            <Route path="*" element={<DefaultLayout onLogout={handleLogout} />} />
          </>
        )}
      </Routes>
    </UserProvider>
  );
}

export default App;
