import React from 'react'
import { Link,  useNavigate, useParams } from "react-router-dom";
import logowhite from '../../Images/logowhite.svg';
import axios from 'axios';

function Thankyou() {
    // localStorage.removeItem('userDetails');
    const uid = useParams();
    
    // redirect("/login");
    const navigate = useNavigate() 
    setTimeout((()=>{
      axios.post(`${process.env.REACT_APP_AUTH_URL}/api/Thankyou/SignIn`,uid)
      .then((res)=>{
        console.log(res);
        const userDetails = res.data;
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
        navigate(`/User/Dashboard`);  
      }).catch((err)=>{
        console.log(err);
      })
      // navigate('/login')
    }),1000)
  return (
    <div className="container-fluid signinBGs">
    
    <div className="row">
      <div className="container">
        <section className="row page-section bgvdolgn formBG">
          <div className="col-lg-12 col-sm-12" >
            <div className="row rightWraps">
              <div className="col-sm-12 panel-wrapper formSignBgs frmlgn">
                <div className="frm-fill formSignBox" style={{left:"50%"}}>
                  <div className="display-flex-column align-content-center justify-content-center full-height flex-center login-container">
                    <div className="main-panel__content main-panel__content--login">
                      <Link className="navbar-brand" to="/">
                        <img src={logowhite} alt="logo" className="logo" height="70"/>
                      </Link>
                    <h3 className='font-weight-bold'>Profiling Survey</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  )
}

export default Thankyou