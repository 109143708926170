import { React, useState } from 'react';
import '../Login/Login.css';
import axios from "axios";
import { Link,useParams, useNavigate  } from "react-router-dom";
import logowhite from '../../Images/logowhite.svg';
import loginimg from '../../Images/loginImg.png';
import Loader from '../../Component/Loader/Loader';

function UserDetails({onLogin}) {
    localStorage.removeItem('userDetails');
    const uid = useParams();
    const navigate = useNavigate();
    localStorage.removeItem('userDetails');
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false); // Add loading state
    const SignIn = process.env.REACT_APP_AUTH_URL;

    
    const [user, setUser] = useState({
      Fname: "",
      Lname:"",
      date:"",
      gender:"",
      zipCode:"",
    });

    console.log(user);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setUser({
        ...user,
        [name]: value
      });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      login();
    };
  
    const login = () => {
      const errors = {};
  
      if (!user.Fname) {
        errors.Fname = "First Name is required";
      }
      if (!user.Lname) {
        errors.Lname = "Last Name is required";
      }
      if (!user.date) {
        errors.date = "DOB is required";
      }
      if (!user.gender) {
        errors.gender = "gender is required";
      }
      if (!user.zipCode) {
        errors.zipCode = "Zip/Post is required";
      }
      setErrors(errors);
  
      if (user.Fname !== '' || user.Lname !== '' || user.date !== '' || user.gender !== '' || user.zipCode !== '') {
        // setIsLoading(true); // Set loading to true before API call
        console.log(uid ," 0 ", user);
        axios.post(`${SignIn}/api/user/UserDetails/${uid.uid}`, user)
        .then(res => {
            if(res.data.message==='right'){
                navigate(`/ProfilingSurvey/${uid.uid}`)
            }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false); // Set loading to false after API call
        });
      }
    };

  return (
    <div className="container-fluid signinBGs">
    {isLoading && <Loader />} {/* Render Loader when loading */}
    <div className="row">
      <div className="container">
        <section className="row page-section bgvdolgn formBG">
          <div className="col-lg-6 col-sm-12">
            <div className="row rightWraps">
              <div className="col-sm-12 panel-wrapper formSignBgs frmlgn">
                <div className="frm-fill formSignBox">
                  <div className="display-flex-column align-content-center justify-content-center full-height flex-center login-container">
                    <div className="main-panel__content main-panel__content--login">
                      <Link className="navbar-brand mobileLogo" to="/">
                        <img src={logowhite} alt="logo" className="logo" height="70"/>
                      </Link>

                      <form onSubmit={handleSubmit}>
                        <h1 className="main-panel__title">Personal Details</h1>
                        <hr className="frmhr"/>
                        <div className="pf-form">
                          <div className="pf-form__message pf-form__message--error pf-form__message--padding"></div>
                          <div className='row'>
                            <div className="pf-form__textfield col-lg-6 col-md-12">
                                <label>First Name</label>
                                <i> <img src="~/image/email.png" alt="icon"/>  </i>
                                <input type="text" value={user.Fname} onChange={handleChange} name="Fname" placeholder="First Name" className="pf-form__textfield__input" />
                                <div className='pf-form__message pf-form__message--error text-left '>
                                {errors.Fname && <p className='text-danger' style={{fontSize:"12px"}}>{errors.Fname}</p>}
                                </div>
                            </div>
                            <div className="pf-form__textfield col-lg-6 col-md-12">
                                <label>Last Name</label>
                                <i> <img src="~/image/email.png" alt="icon"/>  </i>
                                <input type="text" value={user.Lname} onChange={handleChange} name="Lname" placeholder="Last Name" className="pf-form__textfield__input" />
                                <div className='pf-form__message pf-form__message--error text-left '>
                                {errors.Lname && <p className='text-danger' style={{fontSize:"12px"}}>{errors.Lname}</p>}
                                </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className="pf-form__textfield col-lg-6 col-md-12">
                                <label>Date of Birth</label>
                                <i> <img src="~/image/email.png" alt="icon"/>  </i>
                                <input type="date" value={user.date} onChange={handleChange} name="date" placeholder="DOB" className="pf-form__textfield__input" />
                                <div className='pf-form__message pf-form__message--error text-left '>
                                {errors.date && <p className='text-danger' style={{fontSize:"12px"}}>{errors.date}</p>}
                                </div>
                            </div>
                            <div className="pf-form__textfield col-lg-6 col-md-12">
      <label>Gender</label>
      <i>
        <img src="~/image/email.png" alt="icon" />
      </i>
      <select
        name="gender"
        value={user.gender}
        onChange={handleChange}
        className="pf-form__textfield__input"
      >
        <option value="" disabled>
          Select
        </option>
        <option value="male">Male</option>
        <option value="female">Female</option>
      </select>
      <div className='pf-form__message pf-form__message--error text-left'>
        {errors.gender && (
          <p className='text-danger' style={{ fontSize: "12px" }}>
            {errors.gender}
          </p>
        )}
      </div>
    </div>
  
                          </div>
                          <div className='row'>
                            <div className="pf-form__textfield col-lg-6 col-md-12">
                                <label>Zip/Post Code</label>
                                <i> <img src="~/image/email.png" alt="icon"/>  </i>
                                <input type="text" value={user.zipCode} maxLength="6" onChange={handleChange} name="zipCode" placeholder="Zip/Post Code" className="pf-form__textfield__input" />
                                <div className='pf-form__message pf-form__message--error text-left '>
                                {errors.zipCode && <p className='text-danger' style={{fontSize:"12px"}}>{errors.zipCode}</p>}
                                </div>
                            </div>
                           
                          </div>
                          <div className="pf-form__textfield mb-1qq">
                            <input type='submit' value="Submit" className="pf-form__textfield-button pf-form__textfield-button--submit lgnbtn" style={{width:"100%"}} />
                          </div>
                        </div>
                      </form>
                      {/* <div className="pf-copyright">Don't have an account? <Link className="text-large font-weight-bold1 uk-visible@l signupbtn" to='/account'>Sign Up Now</Link> </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-sm-12">
            <div className="row">
              <div className="ctrRight leftsideImg">
                <Link className="navbar-brand mb-4" to="/">
                  <img src={logowhite} alt="logo" className="logo" height="70"/>
                </Link>
                <img src={loginimg} alt="girl" className="phone-img img-fluid" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  )
}

export default UserDetails