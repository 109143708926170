// import React, { useEffect, useState } from 'react';
// import { NavLink, useNavigate, Link } from 'react-router-dom';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import { BsJustify } from 'react-icons/bs';
// import axios from 'axios';
// import profileImg from '../../Images/Ellipse1.png';
// import bellIcon from '../../Images/Group4.png';
// import settingIcon from '../../Images/Group5.png';
// import logowhite from '../../Images/logowhite.svg';
// import droparrow from '../../Images/arrowicon.svg';

// function Topbar({ OpenSidebar }) {
//     const [menu, setMenu] = useState([]);
//     const [dropdownVisible, setDropdownVisible] = useState(false);
//     const [active, setActive] = useState(false);
//     const navigate = useNavigate();

//     useEffect(() => {
//         if (window.location.pathname === '/User/Redeem') {
//             setActive(true);
//         } else {
//             setActive(false);
//         }
//     }, [window.location.pathname]);

//     const toggleDropdown = () => {
//         setDropdownVisible(!dropdownVisible);
//     };

//     const Logout = () => {
//         localStorage.removeItem('userDetails');
//         navigate('/login');


//         // Swal.fire({
//         //     title: "Are you sure?",
//         //     text: "Do you want to Logout",
//         //     icon: "warning",
//         //     showCancelButton: true,
//         //     confirmButtonColor: "#3085d6",
//         //     cancelButtonColor: "#d33",
//         //     confirmButtonText: "Yes"
//         // }).then((result) => {
//         //     if (result.isConfirmed) {
//         //         localStorage.removeItem('userDetails');
//         //         navigate('/login');
//         //         Swal.fire({
//         //             title: "Logout",
//         //             text: "You have successfully logged out",
//         //             icon: "success"
//         //         });
//         //     }
//         // });
//     };

//     useEffect(() => {
//         // Fetch role access data from the backend API
//         axios.get(`${process.env.REACT_APP_AUTH_URL}/api/roleAccess`)
//             .then(response => {
//                 setMenu(response.data);
//             }).catch((error) => {
//                 console.error('Error fetching menu data:', error);
//             });
//     },[]);

//     const color = {
//         color: "white",
//         padding: "8px 30px",
//     };

//     const activebtn = {
//         border: "1px solid #ffffff",
//         borderRadius: "10px",
//         padding: "9px",
//         width: "110%",
//         margin: "-10px",
//         color: "white",
//         padding: "8px 30px",
//     };

//     return (
//         <header className='header'>
//             <div className='menu-icon'>
//                 <BsJustify className='icon' onClick={OpenSidebar} />
//             </div>

//             <div className='w-100 d-flex'>
//                 <img alt='' src={logowhite} className='d-block img-fluid' style={{ width: "200px" }} />
//                 <div className='header-left d-none d-lg-block mt-3' style={{ width: "70%", marginLeft: "8px" }}>
//                     <ul className='d-flex justify-content-between align-content-center col-lg-12' style={{ listStyle: "none", fontSize: "15px" }}>
//                         {/* <li>
//                             <NavLink to='/User/DailyPoll' style={color}>
//                                 Daily Poll
//                             </NavLink>
//                         </li>
//                         <li>
//                             <NavLink to='/User/SurveyWall' style={color}>
//                                 SurveyWall
//                             </NavLink>
//                         </li> */}
//                         {/* <NavLink to={item.route} style={color}>
//                                 {item.name}
//                             </NavLink> */}

//                         {menu.map(item => {
//                             // const isActive = ;
//                             // console.log(window.location.pathname,item.route);
//                             if (item.sortId > 7) {
//                                 (item.children.length > 0) ? (<>
//                                         <li onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
//                                             <Link style={window.location.pathname === item.route ? activebtn : color} to={item.route}>
//                                                 {item.name}

//                                                 <img src={droparrow} style={{
//                                                     marginLeft: "5px", transform: dropdownVisible ? 'rotate(-40deg)' : 'rotate(0deg)',
//                                                     transition: 'transform 0.3s ease'
//                                                 }} />


//                                             </Link>

//                                             <ul className={`dropdown-menu ${dropdownVisible ? 'show' : ''}`} style={{ marginTop: '8px' }}>
//                                                 <li className="dropdown-item topbar-hover text-white" style={{ borderBottom: "1px solid grey" }}>
//                                                     <Link to={item.children[0].route} className="nav-link p-1">
//                                                         {item.children[0].name}
//                                                     </Link>
//                                                 </li>
//                                                 {/* <li className="dropdown-item topbar-hover text-white" style={{ borderBottom: "1px solid grey" }}>
//                                         <Link to="/User/Redeem" className="nav-link p-1">
//                                             Redeem points
//                                         </Link>
//                                     </li>
//                                     <li className="dropdown-item topbar-hover text-white" style={{ borderBottom: "1px solid grey" }}>
//                                         <Link to="" className="nav-link p-1">
//                                             Redeem Gift Voucher
//                                         </Link>
//                                     </li>
//                                     <li className="dropdown-item topbar-hover text-white">
//                                         <Link to="" className="nav-link p-1">
//                                             XOXO Gift Voucher
//                                         </Link>
//                                     </li> */}
//                                             </ul>



//                                         </li>
//                                     </>) : (<>
//                                         <li>
//                                             <Link style={window.location.pathname === item.route ? activebtn : color} to={item.route}>
//                                                 {item.name}
//                                                 </Link>
// </li>
//                                     </>)
//                             }
//                         })}
//                         {/* <li onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
//                             <Link className={`${active ? 'active' : ''}`} style={color}>
//                                 Redeem
//                                 <img src={droparrow} style={{
//                                     marginLeft: "5px", transform: dropdownVisible ? 'rotate(-40deg)' : 'rotate(0deg)',
//                                     transition: 'transform 0.3s ease'
//                                 }} />
//                             </Link>
//                             <ul className={`dropdown-menu ${dropdownVisible ? 'show' : ''}`} style={{ marginTop: '8px' }}>
//                                 <li className="dropdown-item topbar-hover text-white" style={{ borderBottom: "1px solid grey" }}>
//                                     <Link to="/User/Redeem" className="nav-link p-1">
//                                         Redeem points
//                                     </Link>
//                                 </li>
//                                 <li className="dropdown-item topbar-hover text-white" style={{ borderBottom: "1px solid grey" }}>
//                                     <Link to="" className="nav-link p-1">
//                                         Redeem Gift Voucher
//                                     </Link>
//                                 </li>
//                                 <li className="dropdown-item topbar-hover text-white">
//                                     <Link to="" className="nav-link p-1">
//                                         XOXO Gift Voucher
//                                     </Link>
//                                 </li>
//                             </ul>   
//                         </li> */}
//                     </ul>
//                 </div>
//             </div>

//             <div className='header-right d-flex justify-content-end w-50 p-3' style={{ textAlign: "end" }}>
//                 <div className='d-none d-lg-block'>
//                     <img src={bellIcon} alt='' className='mx-1' />
//                     <img src={settingIcon} alt='' className='mx-1' />
//                 </div>
//                 <img src={profileImg} alt="profile" id="nav-dropdown-dark-example" className='mx-1' />
//                 <NavDropdown
//                     className='mt-2'
//                     id="nav-dropdown-dark-example"
//                     menuVariant="dark"
//                 >
//                     <NavDropdown.Item>Action</NavDropdown.Item>
//                     <NavDropdown.Item>
//                         Another action
//                     </NavDropdown.Item>
//                     <NavDropdown.Item>Something</NavDropdown.Item>
//                     <NavDropdown.Item><button onClick={Logout} className='bg-danger w-100'>Logout</button></NavDropdown.Item>
//                 </NavDropdown>
//             </div>
//         </header>
//     );
// }

// export default Topbar;


import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import { BsJustify } from 'react-icons/bs';
import axios from 'axios';
// import profileImg from '../../Images/Ellipse1.png';
import bellIcon from '../../Images/Group4.png';
import settingIcon from '../../Images/Group5.png';
import logowhite from '../../Images/logowhite.svg';
import droparrow from '../../Images/arrowicon.svg';
import { UserContext } from '../../Component/UserContext';


function Topbar({ OpenSidebar }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
     const image = 'https://as1.ftcdn.net/v2/jpg/03/39/45/96/1000_F_339459697_XAFacNQmwnvJRqe1Fe9VOptPWMUxlZP8.jpg'
    const [menu, setMenu] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { details } = useContext(UserContext);
    const { navItem } = useContext(UserContext);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'))


    useEffect(() => {
        if (navItem && navItem.length > 0) {
          setMenu(navItem);
        }
      }, [navItem]);
    // useEffect(() => {
    //     axios.get(`${process.env.REACT_APP_AUTH_URL}/api/roleAccess`)
    //         .then(response => {
    //             setMenu(response.data);
    //         })
    //         .catch(error => {
    //             console.error('Error fetching menu data:', error);
    //         });
    // }, []);

    const toggleDropdown = (index) => {
        setDropdownVisible(dropdownVisible === index ? null : index);
    };

    const handleProfileClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const Logout = (role) => {
        localStorage.removeItem('userDetails');
        if (role !== "2") {
            navigate('/admin/login');
        } else {
            navigate('/login');
        }
    };
    
    const color = {
        color: "white",
        // padding: "8px 30px",
    };

    const activebtn = {
        border: "1px solid #ffffff",
        borderRadius: "10px",
        padding: "9px",
        width: "110%",
        margin: "-10px",
        color: "white",
        // padding: "8px 30px",
    };

    return (
        <header className='header'>
            <div className='menu-icon'>
                <BsJustify className='icon' onClick={OpenSidebar} />
            </div>

            <div className='w-100 d-flex'>
                <img alt='' src={logowhite} className='d-block img-fluid' style={{ width: "200px" }} />
                {(userDetails.role==="2")?(<>
                    <div className='header-left d-none d-lg-block mt-3' style={{ width: "50%", marginLeft: "20px" }}>
                    <ul className='d-flex justify-content-between align-content-center col-lg-12' style={{ listStyle: "none", fontSize: "15px" }}>
                        {menu.map((item, index) => {
                            const itemRouteWithSlash = `/${item.route}`;
                            const isActive = location.pathname === itemRouteWithSlash;
                            if (item.sortId > 7) {
                                // console.log(`Current path: ${location.pathname}, Item route: ${item.route}, Is active: ${isActive}`);
                                return (
                                    <li key={item.route} onMouseEnter={() => toggleDropdown(index)} onMouseLeave={() => toggleDropdown(index)}>
                                        <Link style={isActive ? activebtn : color} to={item.route}>
                                            {item.name}
                                            {item.children.length > 0 && (
                                                <img src={droparrow} style={{
                                                    marginLeft: "5px",
                                                    transform: dropdownVisible === index ? 'rotate(-40deg)' : 'rotate(0deg)',
                                                    transition: 'transform 0.3s ease'
                                                }} alt='dropdown arrow' />
                                            )}
                                        </Link>
                                        {item.children.length > 0 && dropdownVisible === index && (
                                            <ul className={`dropdown-menu show`} style={{ marginTop: '8px' }}>
                                                {item.children.map(child => (
                                                    <li key={child.route} className="dropdown-item topbar-hover text-white" style={{ borderBottom: "1px solid grey" }}>
                                                        <Link to={child.route} className="nav-link p-1">
                                                            {child.name}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </div>
                </>):(<></>)}
                
            </div>

            <div className='header-right d-flex justify-content-end w-50 p-3' style={{ textAlign: "end" }}>
                <div className='d-none d-lg-block'>
                    <img src={bellIcon} alt='' className='m-1' style={{height:"35px"}}/>
                    <img src={settingIcon} alt='' className='m-1' style={{height:"35px"}}/>
                </div>

                <div onClick={handleProfileClick} className='m-1 cursor'>
                    <img  src={details?.profileImg || image} alt="profile" className='profile-icon mx-1' style={{height:'35px', width:"38px", borderRadius:"50px",objectFit: "cover"}} />
                    <img src={droparrow} alt=''/>
                </div>
                <Dropdown style={{marginTop: "40px"}} show={isDropdownOpen} onToggle={() => setIsDropdownOpen(!isDropdownOpen)}>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                        {/* Custom Toggle contents, if needed */}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item>Setting</Dropdown.Item>
                        <Dropdown.Item to='/'><Link className='text-white' to='/ChangePassword'>Change Password</Link></Dropdown.Item>
                        <Dropdown.Item>
                            <button onClick={(e) => Logout(userDetails.role)} className='bg-danger w-100'>Logout</button>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                {/* <img src={profileImg} alt="profile" id="nav-dropdown-dark-example" className='mx-1' />
                
                <NavDropdown
                    className='mt-2'
                    id="nav-dropdown-dark-example"
                    menuVariant="dark"
                >
                    <NavDropdown.Item>Action</NavDropdown.Item>
                    <NavDropdown.Item>
                        Another action
                    </NavDropdown.Item>
                    <NavDropdown.Item>Something</NavDropdown.Item>
                    <NavDropdown.Item><button onClick={(e)=>Logout(userDetails.role)} className='bg-danger w-100'>Logout</button></NavDropdown.Item>
                </NavDropdown> */}
            </div>
        </header>
    );
}


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {/* Render custom icon or image here */}
        {children}
    </div>
));

export default Topbar;


