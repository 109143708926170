// import {React, useState} from 'react'
// import Sidebar from '../Component/UserDashboardComp/Sidebar'
// import Home from '../Component/HomePage/Home'
// // import Home from './Home'
// import Topbar from '../Component/UserDashboardComp/Topbar'
// import UserDashboard from '../Areas/User/UserDashboard'
// import { Routes, Route } from 'react-router-dom'
// // const Dashboard = React.lazy(() => import('./Dashboard/Dashboard.js'));

// function DefaultLayout() {
//     const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

//     const OpenSidebar = () => {
//       setOpenSidebarToggle(!openSidebarToggle)
//     }

//     var userDetails = JSON.parse(localStorage.getItem('userDetails'));
//     var role = userDetails.role;
  
//   return (
//     <>
//     {(!role)?<>
      
//       <Routes>
//         <Route path='/' element={<Home/>} />
//       </Routes>

//     </>:<></>}
//       {(role==='2')?(<>
//         <div className='grid-container'>
//           <Topbar OpenSidebar={OpenSidebar}/>
//           <Sidebar role={role} openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
//           <UserDashboard role={role} />
//         </div>
//       </>):(<>
//         <div className='grid-container'>
//           <Topbar OpenSidebar={OpenSidebar}/>
//           <Sidebar role={role} openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
//           <UserDashboard role={role} />
//         </div>
//       </>)}
      
      
//     </>
//   )
// }

// export default DefaultLayout;

import React, { useState} from 'react';
import { Routes, Route} from 'react-router-dom';
// import axios from 'axios';
import Sidebar from '../Component/UserDashboardComp/Sidebar';
// import Error from '../Component/Error';
import Topbar from '../Component/UserDashboardComp/Topbar';
import UserDashboard from '../Areas/User/UserDashboard';
// import Login from './Login/SignIn';
import Unauthorized from './Unauthorized';

function DefaultLayout() {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  // const [details, setDetails] = useState();
  // const navigate = useNavigate();

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const role = userDetails?.role;
  // const uid = userDetails?.id;
  // const id = {
  //   'id':uid
  // }

  // useEffect(() => {
  //     axios.post(`${process.env.REACT_APP_AUTH_URL}/api/user/details`,id)
  //       .then(response => {
  //         console.log(response.data.message);
  //         setDetails(response.data.message)
  //         // setShouldFetch(false); // Set to false after fetching data
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching data:', error);
  //         // setError(error);
  //         // setShouldFetch(false); // Set to false to prevent continuous fetching in case of error
  //       });
  // }, []); // Run the effect when shouldFetch changes


  // useEffect(() => {
  //   const location = window.location.pathname;
  //   if (location === '/login'|| location === '/') {
  //     localStorage.removeItem('userDetails');
  //     navigate(-1); // Navigate back
  //   }
  // }, [navigate]);

  if (!role) {
    return (
      <Routes>
        <Route path="*" element={<Unauthorized />} />
      </Routes>
    );
  }

  return (
    <div className="grid-container">
      
      <Topbar OpenSidebar={OpenSidebar} />
      <Sidebar role={role} openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
      <UserDashboard role={role}  />
    </div>
  );
}

export default DefaultLayout;
