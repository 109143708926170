import React, { useEffect, useState, useRef, useContext } from 'react';
import { NavLink, Link } from 'react-router-dom';
import axios from 'axios';
import logo from '../../Images/logowhite.svg';
// import profileImg from '../../Images/Group2.png';
import { UserContext } from '../../Component/UserContext';

function Sidebar({ openSidebarToggle, OpenSidebar, role }) {
    const [dropdownVisible,] = useState(false);
    // const [profile, setProfile] = useState('https://as1.ftcdn.net/v2/jpg/03/39/45/96/1000_F_339459697_XAFacNQmwnvJRqe1Fe9VOptPWMUxlZP8.jpg')
    // const image = 'https://as1.ftcdn.net/v2/jpg/03/39/45/96/1000_F_339459697_XAFacNQmwnvJRqe1Fe9VOptPWMUxlZP8.jpg'
    const [menu, setMenu] = useState([]);
    const { details } = useContext(UserContext);
    const { navItem } = useContext(UserContext);
    const sidebarRef = useRef(null);
    // const user = JSON.parse(localStorage.getItem('userDetails'));
    // const name = user.email.split("@");
    const [profile, setProfile] = useState(details.profileImg || 'https://as1.ftcdn.net/v2/jpg/03/39/45/96/1000_F_339459697_XAFacNQmwnvJRqe1Fe9VOptPWMUxlZP8.jpg');

    useEffect(() => {
        if (details?.profileImg) {
            setProfile(details.profileImg);
        }
    }, [details?.profileImg]);
    

    useEffect(() => {
        // Fetch role access data from the backend API
        // axios.get(`${process.env.REACT_APP_AUTH_URL}/api/roleAccess`)
        //     .then(response => {
        //         setMenu(response.data);
        //     }).catch((error) => {
        //         console.error('Error fetching menu data:', error);
        //     });

        if (navItem && navItem.length > 0) {
            setMenu(navItem);
          }

        // Add event listener to detect clicks outside the sidebar
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Clean up the event listener
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [navItem]); // Empty dependency array to ensure useEffect runs only once

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target) && openSidebarToggle) {
            OpenSidebar();
        }
    };

    const handleMenuItemClick = () => {
        if (openSidebarToggle) {
            OpenSidebar();
        }
    };

    const renderMenuItems = (menuItems) => {
        return menuItems.map((item, index) => {
            // Check if the role has access to the menu item
            const hasAccess = item.menuAccess.some(access => access.roleId == role);
            const isActive = window.location.pathname === item.route;

            if (!hasAccess) return null;
            if (item.sortId > 7) {
                return (
                    <React.Fragment key={index}>
                        <li onClick={handleMenuItemClick} className='d-block d-lg-none'>
                            <NavLink
                                className='text-decoration sidebar-list-item d-flex mb-1 cursor'
                                to={item.route}
                                style={isActive ? active : {}}
                            >
                                <img
                                    alt=''
                                    className='mx-2'
                                    src={`/images/${item.icon}`}
                                    style={{ verticalAlign: "middle", width: "20px", height: "20px", marginTop: "-3px" }}
                                />
                                <span>{item.name}</span>
                            </NavLink>
                            {item.children.length > 0 && (
                                <ul className={`dropdown-menu ${dropdownVisible ? 'show' : ''}`} style={{ marginTop: '8px' }}>
                                    {item.children.map((child, childIndex) => (
                                        <li key={`child-${index}-${childIndex}`} className="dropdown-item topbar-hover text-white" style={{ borderBottom: "1px solid grey" }}>
                                            <Link to={child.route} className="nav-link p-1">
                                                {child.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    </React.Fragment>
                );
            }
            if(item.menuId==="17"){
                return (
                    <React.Fragment key={index}>
                        <li onClick={handleMenuItemClick} className='d-none'>
                            <NavLink
                                className='text-decoration sidebar-list-item d-flex mb-1 cursor'
                                to={item.route}
                                style={isActive ? active : {}}
                            >
                                <img
                                    alt=''
                                    className='mx-2'
                                    src={`/images/${item.icon}`}
                                    style={{ verticalAlign: "middle", width: "20px", height: "20px", marginTop: "-3px" }}
                                />
                                <span>{item.name}</span>
                            </NavLink>
                            {item.children.length > 0 && (
                                <ul className={`dropdown-menu ${dropdownVisible ? 'show' : ''}`} style={{ marginTop: '8px' }}>
                                    {item.children.map((child, childIndex) => (
                                        <li key={`child-${index}-${childIndex}`} className="dropdown-item topbar-hover text-white" style={{ borderBottom: "1px solid grey" }}>
                                            <Link to={child.route} className="nav-link p-1">
                                                {child.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    </React.Fragment>
                );
            
            }
            return (
                <React.Fragment key={index}>
                    <li onClick={handleMenuItemClick}>
                        <NavLink
                            className='text-decoration sidebar-list-item d-flex mb-1 cursor'
                            to={item.route}
                            style={isActive ? active : {}}
                        >
                            <img
                                alt=''
                                className='mx-2'
                                src={`/images/${item.icon}`}
                                style={{ verticalAlign: "middle", width: "20px", height: "20px", marginTop: "-3px" }}
                            />
                            <span>{item.name}</span>
                        </NavLink>
                        {item.children.length > 0 && (
                            <ul className='sidebar-sublist'>
                                {item.children.map((child, childIndex) => (
                                    <li key={`child-${index}-${childIndex}`} onClick={handleMenuItemClick}>
                                        <NavLink
                                            className='text-decoration sidebar-list-item d-flex mb-1 cursor'
                                            to={child.route}
                                            style={isActive ? active : {}}
                                        >
                                            <img
                                                alt=''
                                                className='mx-2'
                                                src={`/images/${child.icon}`}
                                                style={{ verticalAlign: "middle", width: "20px", height: "20px", marginTop: "-3px" }}
                                            />
                                            <span>{child.name}</span>
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                </React.Fragment>
            );
        });
    };

    const active = {
        border: "1px solid #ffffff",
        borderRadius: "10px",
        padding: "9px",
        width: "110%",
        margin: "-10px"
    };

    return (
        <aside id="sidebar" ref={sidebarRef} className={openSidebarToggle ? "sidebar-responsive" : ""}>
            <div className='sidebar-title d-block d-lg-none'>
                <div className='sidebar-brand '>
                    <img src={logo} alt='' style={{ width: "180px" }} />
                    <span className='icon close_icon' onClick={OpenSidebar}>X</span>
                </div>
            </div>

            <div className='container mt-3'>
                <div className='' style={{ textAlign: "center" }}>
                    <img src={ profile} alt='' className='' style={{height:"100px", width:"100px", borderRadius:"50px",objectFit: "cover"}} />
                    <p className='mt-1'>{details?.firstName || 'User'} {details?.lastName || ""}</p>
                    <hr />
                </div>

                <ul className='sidebar-list scroll-container'>
                    {renderMenuItems(menu)}
                </ul>
            </div>
        </aside>
    );
}

export default Sidebar;
