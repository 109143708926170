import { React, useState } from 'react';
import '../Login/Login.css';
import axios from "axios";
import { Link,useParams, useNavigate  } from "react-router-dom";
import logowhite from '../../Images/logowhite.svg';
import loginimg from '../../Images/loginImg.png';
import Loader from '../../Component/Loader/Loader';

function ActivationCode({onLogin}) {
    localStorage.removeItem('userDetails');
    const uid = useParams();
    const navigate = useNavigate();
    localStorage.removeItem('userDetails');
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false); // Add loading state
    const SignIn = process.env.REACT_APP_AUTH_URL;

    
    const [user, setUser] = useState({
      code: "",
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setUser({
        ...user,
        [name]: value
      });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      login();
    };
  
    const login = () => {
      const errors = {};
  
      if (!user.code) {
        errors.code = "Code is required";
      }
      setErrors(errors);
  
      if (user.code !== '') {
        console.log(user);
        setIsLoading(true); // Set loading to true before API call
        axios.post(`${SignIn}/api/user/verifyAccount/${uid.uid}`, user)
        .then(res => {
            if(res.data.message==='right'){
                navigate(`/userDetails/${uid.uid}`)
            }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false); // Set loading to false after API call
        });
      }
    };

  return (
    <div className="container-fluid signinBGs">
    {isLoading && <Loader />} {/* Render Loader when loading */}
    <div className="row">
      <div className="container">
        <section className="row page-section bgvdolgn formBG">
          <div className="col-lg-6 col-sm-12">
            <div className="row rightWraps">
              <div className="col-sm-12 panel-wrapper formSignBgs frmlgn">
                <div className="frm-fill formSignBox">
                  <div className="display-flex-column align-content-center justify-content-center full-height flex-center login-container">
                    <div className="main-panel__content main-panel__content--login">
                      <Link className="navbar-brand mobileLogo" to="/">
                        <img src={logowhite} alt="logo" className="logo" height="70"/>
                      </Link>

                      <form onSubmit={handleSubmit}>
                        <h1 className="main-panel__title">Activation Code</h1>
                        <hr className="frmhr"/>
                        <div className="pf-form">
                          <div className="pf-form__message pf-form__message--error pf-form__message--padding"></div>
                          <div className="pf-form__textfield">
                            <label></label>
                            <i> <img src="~/image/email.png" alt="icon"/>  </i>
                            <input type="text" value={user.code} onChange={handleChange} name="code" placeholder="Activation Code*" className="pf-form__textfield__input" />
                            <div className='pf-form__message pf-form__message--error text-left '>
                              {errors.code && <p className='text-danger' style={{fontSize:"12px"}}>{errors.code}</p>}
                            </div>
                          </div>
                          <div className="pf-form__textfield mb-1qq">
                            <input type='submit' value="Submit" className="pf-form__textfield-button pf-form__textfield-button--submit lgnbtn" style={{width:"100%"}} />
                          </div>
                        </div>
                      </form>
                      <button className=''>Resend</button>
                      {/* <div className="pf-copyright">Don't have an account? <Link className="text-large font-weight-bold1 uk-visible@l signupbtn" to='/account'>Sign Up Now</Link> </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-sm-12">
            <div className="row">
              <div className="ctrRight leftsideImg">
                <Link className="navbar-brand mb-4" to="/">
                  <img src={logowhite} alt="logo" className="logo" height="70"/>
                </Link>
                <img src={loginimg} alt="girl" className="phone-img img-fluid" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  )
}

export default ActivationCode