// // UserContext.js
// import React, { createContext, useState, useEffect } from 'react';
// import axios from 'axios';

// export const UserContext = createContext();

// export const UserProvider = ({ children }) => {
//   const [details, setDetails] = useState(null);
//   const userDetails = JSON.parse(localStorage.getItem('userDetails'));
//   const uid = userDetails?.id;

//   useEffect(() => {
//     if (uid) {
//       axios.post(`${process.env.REACT_APP_AUTH_URL}/api/user/details`, { id: uid })
//         .then(response => {
//           setDetails(response.data.message);
//         })
//         .catch((error) => {
//           console.error('Error fetching data:', error);
//         });
//     }
//   }, [uid]);

//   return (
//     <UserContext.Provider value={{ details, setDetails }}>
//       {children}
//     </UserContext.Provider>
//   );
// };

// UserContext.js

// UserContext.js
// import React, { createContext, useState, useEffect } from 'react';
// import axios from 'axios';

// export const UserContext = createContext();

// export const UserProvider = ({ children }) => {
//   const [details, setDetails] = useState({
//     email: '',
//     contact: '',
//     password: '',
//     role:'',
//     otp:'',
//     status:'',
//     firstName:'',
//     lastName:'',
//     dob:'',
//     gender:'',
//     zip: '',
//     education:'',
//     employmentStatus:'',
//     race:'',
//     industries:'',
//     responsibility:'',
//     relationship:'',
//     profileImg:'',
//   });

//   const [navItem, setNavItem] = useState();
//   const userDetails = JSON.parse(localStorage.getItem('userDetails'));
//   const uid = userDetails?.id;
//   // console.log(uid);
//   useEffect(() => {
//     if (uid) {
//       axios.post(`${process.env.REACT_APP_AUTH_URL}/api/user/details`, { id: uid })
//         .then(response => {
//           setDetails(prevDetails => ({
//             ...prevDetails,
//             ...response.data.message
//           }));
//         })
//         .catch((error) => {
//           console.error('Error fetching data:', error);
//         });
//     }
//   },[uid, details.profileImg]);
//   const updateProfileImg = (newProfileImg) => {
//     setDetails((prevDetails) => ({
//       ...prevDetails,
//       profileImg: newProfileImg,
//     }));
//   };


//   useEffect(()=>{
//     axios.get(`${process.env.REACT_APP_AUTH_URL}/api/roleAccess`).then((res)=>{
//       setNavItem(res.data)
//     }).catch((err)=>{
//       console.log(err);
//     })  
//   },[])
//   console.log(navItem);

//   return (
//     <UserContext.Provider value={{ details, updateProfileImg,navItem}}>
//       {children}
//     </UserContext.Provider>
//   );
// };


import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [details, setDetails] = useState({
    email: '',
    contact: '',
    password: '',
    role: '',
    otp: '',
    status: '',
    firstName: '',
    lastName: '',
    dob: '',
    gender: '',
    zip: '',
    education: '',
    employmentStatus: '',
    race: '',
    industries: '',
    responsibility: '',
    relationship: '',
    profileImg: '',
  });

  const [navItem, setNavItem] = useState([]);
  // const [loading, setLoading] = useState(true); // Added loading state
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const uid = userDetails?.id;

  useEffect(() => {
    if (uid) {
      axios.post(`${process.env.REACT_APP_AUTH_URL}/api/user/details`, { id: uid })
        .then(response => {
          setDetails(prevDetails => ({
            ...prevDetails,
            ...response.data.message
          }));
        })
        .catch((error) => {
          console.error('Error fetching user details:', error);
        });
    }
  }, [uid]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_AUTH_URL}/api/roleAccess`)
      .then(response => {
        if (Array.isArray(response.data)) {
          setNavItem(response.data);
        } else {
          console.error('Unexpected response format:', response.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching role access data:', error);
      })
      .finally(() => {
        // setLoading(false); // Set loading to false after fetching data
      });
  }, []);
  console.log(navItem);
  const updateProfileImg = (newProfileImg) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      profileImg: newProfileImg,
    }));
  };

  return (
    <UserContext.Provider value={{ details, updateProfileImg, navItem }}>
      {children} {/* Show loading or children */}
    </UserContext.Provider>
  );
};
