// import React, { useEffect, useState, Suspense, useContext } from 'react';
// import './UserDashboard.css';
// import axios from 'axios';
// import { Route, Routes } from 'react-router-dom';
// import Error from '../../Component/Error';
// import Loader from '../../Component/Loader/Loader';
// import SurveyList from './SurveyList/SurveyList';
// import firstLogin from '../../Images/loginImg.png';
// import { UserContext } from '../../Component/UserContext';

// const Home = ({ role }) => {
//   const [menu, setMenu] = useState([]);
//   const [error, setError] = useState(null);
//   const [shouldFetch, setShouldFetch] = useState(true);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const {navItem} = useContext(UserContext)

//   useEffect(() => {
//     const details = JSON.parse(localStorage.getItem('userDetails'));
//     if (details && details.done) {
//       setIsModalOpen(true);
//     }
//   }, []);

//   useEffect(() => {
//     if (shouldFetch) {
//       setMenu(navItem);
//       console.log(menu);
//       console.log("inner");
//       setShouldFetch(false)
//       // axios.get(`${process.env.REACT_APP_AUTH_URL}/api/roleAccess`)
//       //   .then(response => {
//       //     setMenu(response.data);
//       //     setShouldFetch(false);
//       //   })
//       //   .catch((error) => {
//       //     console.error('Error fetching data:', error);
//       //     setError(error);
//       //     setShouldFetch(false);
//       //   });
//     }
//   }, [shouldFetch]);

//   if (menu.length === 0 && !error) {
//     return <Loader />;
//   }

//   if (error) {
//     return <div>Error fetching data: {error.message}</div>;
//   }

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     const details = JSON.parse(localStorage.getItem('userDetails'));
//     if (details) {
//       delete details.done;
//       localStorage.setItem('userDetails', JSON.stringify(details));
//     }
//   };

//   return (
//     <>
//       {isModalOpen && (
//         <div className="modal fade show" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" style={{ display: "block" }}>
//           <div className="modal-dialog modal-dialog-centered" role="document">
//             <div className="modal-content  outer" style={{backgroundColor:"#333", borderRadius:"10px", marginTop:"-30px"}}>
//               <div className="modal-body d-flex justify-content-center bg-black" style={{borderRadius:"10px"}}>
//                 <img src={firstLogin} className='img-fluid' style={{height:"350px"}} alt="First Login"/>
//               </div>
//               <div className="modal-footer text-center justify-content-center" style={{borderTop:"none"}}>
//                 <h4 className=''>Welcome to SurveyCliq!</h4>
//                 <p>We are excited to have you as a member of SurveyCliq. Let's take a tour.</p>
//                 <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleCloseModal} style={{minWidth:'250px', backgroundColor:"#0d1b22", border:"#0d1b22"}}>Ok</button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       <main className='main-container'>
//         <div className='outer row d-flex'>
//           <div className='userDashboard leftSide'>
//             <div className='mt-3'>
//               <Suspense fallback={<div>Loading...</div>}>
//                 <Routes>
//                   {menu.map((route, idx) => {
//                     for (let i = 0; i < route.menuAccess.length; i++) {
//                       if (route.menuAccess[i].roleId === role && route.route != null && route.element != null && route.elementPath != null) {
//                         return (
//                           <Route
//                             key={idx}
//                             path={route.route}
//                             element={ElementLoader(route.elementPath)}
//                           />
//                         );
//                       }
//                     }
//                     return <Route key={idx} path='*' element={<Error />} />;
//                   })}
//                 </Routes>
//               </Suspense>
//             </div>
//           </div>

//           {role === "2" && (
//             <div className='userDashboard rightside'>
//               <div className='mt-3'>
//                 <SurveyList />
//               </div>
//             </div>
//           )}
//         </div>
//       </main>
//     </>
//   );
// };

// const ElementLoader = (elementpath) => {
//   const Component = React.lazy(() => import(`../${elementpath}`));
//   return <Component />;
// };

// export default Home;

import React, { useEffect, useState, Suspense, useContext } from 'react';
import './UserDashboard.css';
import { Route, Routes } from 'react-router-dom';
import Error from '../../Component/Error';
import Loader from '../../Component/Loader/Loader';
import SurveyList from './SurveyList/SurveyList';
import firstLogin from '../../Images/loginImg.png';
import { UserContext } from '../../Component/UserContext';

const Home = ({ role }) => {
  const [menu, setMenu] = useState([]);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { navItem } = useContext(UserContext);

  useEffect(() => {
    const details = JSON.parse(localStorage.getItem('userDetails'));
    if (details && details.done) {
      setIsModalOpen(true);
    }
  }, []);

  useEffect(() => {
    if (navItem && navItem.length > 0) {
      setMenu(navItem);
    }
  }, [navItem]);

  if (menu.length === 0 && !error) {
    return <Loader />;
  }

  if (error) {
    return <div>Error fetching data: {error.message}</div>;
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
    const details = JSON.parse(localStorage.getItem('userDetails'));
    if (details) {
      delete details.done;
      localStorage.setItem('userDetails', JSON.stringify(details));
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className="modal fade show" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content  outer" style={{backgroundColor:"#333", borderRadius:"10px", marginTop:"-30px"}}>
              <div className="modal-body d-flex justify-content-center bg-black" style={{borderRadius:"10px"}}>
                <img src={firstLogin} className='img-fluid' style={{height:"350px"}} alt="First Login"/>
              </div>
              <div className="modal-footer text-center justify-content-center" style={{borderTop:"none"}}>
                <h4 className=''>Welcome to SurveyCliq!</h4>
                <p>We are excited to have you as a member of SurveyCliq. Let's take a tour.</p>
                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleCloseModal} style={{minWidth:'250px', backgroundColor:"#0d1b22", border:"#0d1b22"}}>Ok</button>
              </div>
            </div>
          </div>
        </div>
      )}

      <main className='main-container'>
        <div className='outer row d-flex'>
          <div className='userDashboard leftSide'>
            <div className='mt-3'>
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  {menu.map((route, idx) => (
                    route.menuAccess.some(access => access.roleId === role) && route.route && route.element && route.elementPath
                      ? <Route key={idx} path={route.route} element={ElementLoader(route.elementPath)} />
                      : <Route key={idx} path='*' element={<Error />} />
                  ))}
                </Routes>
              </Suspense>
            </div>
          </div>

          {role === "2" && (
            <div className='userDashboard rightside'>
              <div className='mt-3'>
                <SurveyList />
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

const ElementLoader = (elementpath) => {
  const Component = React.lazy(() => import(`../${elementpath}`));
  return <Component />;
};

export default Home;

