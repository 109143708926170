import React, { useState } from 'react';
import './Register.css';
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Loader from '../../Component/Loader/Loader';
import logowhite from '../../Images/logowhite.svg';
import loginimg from '../../Images/signup.png';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

function Register() {
    localStorage.removeItem('userDetails');
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [phone, setPhone] = useState('');

    const [user, setUser] = useState({
        email: "",
        contact: "",
        password: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser({
            ...user,
            [name]: value
        });
    };

    const register = (e) => {
        e.preventDefault();
        const errors = {};

        if (!user.email) {
            errors.email = "Email is required";
        }
        if (!phone) {
            errors.contact = "Contact is required";
        }
        if (!user.password) {
            errors.password = "Password is required";
        }
        setErrors(errors);

        if (user.email && phone && user.password) {
            setIsLoading(true);
            axios.post(`${process.env.REACT_APP_AUTH_URL}/api/auth/signup`, { ...user, contact: phone })
                .then(res => {
                    // console.log(res.status);
                    // if (res.data.message === "User was registered successfully!") {
                        // console.log(res.data.message);
                        navigate(`/ActivationCode/${res.data.message}`)
                        // toast.success('Register successfully');
                        // navigate('/login');
                    // }
                    //  else
                      if (res.data.message === "Email is already registered.") {
                        errors.email = 'Email already registered';
                        setErrors(errors);
                    }
                })
                .catch(err => {
                    console.error("Registration Error: ", err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const marginBottom= {
        marginBottom:"-5px"
    }

    return (
        <div className="container-fluid signinBGs">
            {isLoading && <Loader />}
            <div className="row">
                {/* <ToastContainer /> */}
                <div className="container">
                    <section className="row page-section bgvdolgn formBG">
                        <div className="col-lg-6 col-sm-12">
                            <div className="row rightWraps">
                                <div className="col-sm-12 panel-wrapper formSignBgs frmlgn">
                                    <div className="frm-fill formSignBox">
                                        <div className="display-flex-column align-content-center justify-content-center full-height flex-center login-container">
                                            <div className="main-panel__content main-panel__content--login">
                                                <Link className="navbar-brand mobileLogo" to="/">
                                                    <img src={logowhite} alt="logo" className="logo" height="70" />
                                                </Link>
                                                <form>
                                                    <h1 className="main-panel__title">Register</h1>
                                                    <hr className="frmhr" />
                                                    <div className="pf-form">
                                                        <div className="pf-form__message pf-form__message--error pf-form__message--padding"></div>
                                                        <div className="pf-form__textfield">
                                                            <label>Email</label>
                                                            <input type="text" value={user.email} onChange={handleChange} name="email" placeholder="Email" className="pf-form__textfield__input" />
                                                            <div className='pf-form__message pf-form__message--error text-left'>
                                                            {errors.email && <p className='text-danger' style={{ fontSize: "12px" }}>{errors.email}</p>}
                                                            </div>
                                                        </div>

                                                        <div className=" pf-form__textfield">
                                                            <div className="text-left">
                                                                <label>Mobile No.</label>
                                                            </div>
                                                            <div className="input-group grpAddon">
                                                                <PhoneInput
                                                                    className='pf-form__textfield__input'
                                                                    country={'in'}
                                                                    value={phone}
                                                                    onChange={setPhone}
                                                                    style={marginBottom}
                                                                />
                                                            </div>
                                                            <div className='pf-form__message pf-form__message--error text-left'>{errors.contact && <p className='text-danger' style={{ fontSize: "12px" }}>{errors.contact}</p>}
                                                            </div>
                                                        </div>

                                                        <div className="pf-form__textfield">
                                                            <label>Password</label>
                                                            <input type="password" value={user.password} onChange={handleChange} name="password" className="pf-form__textfield__input pf-clearer password pwd" placeholder="Password" />
                                                            <div className='pf-form__message pf-form__message--error text-left'>
                                                            {errors.password && <p className='text-danger' style={{ fontSize: "12px" }}>{errors.password}</p>}
                                                            </div>
                                                        </div>
                                                        <div className="pf-form__textfield mb-1qq">
                                                            <button type='submit' onClick={register} value='Submit' className="pf-form__textfield-button lgnbtn" style={{ width: "100%" }} >Submit</button>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="pf-copyright">Don't have an account? <Link className="text-large font-weight-bold1 uk-visible@l signupbtn" to='/login'>Sign Up Now</Link> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-sm-12">
                            <div className="row">
                                <div className="ctrRight leftsideImg">
                                    <Link className="navbar-brand mb-4" to="/">
                                        <img src={logowhite} alt="logo" className="logo" height="70" />
                                    </Link>
                                    <img src={loginimg} alt="girl" className="phone-img img-fluid" />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default Register;
